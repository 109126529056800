import React, {Component} from 'react';

import _ from 'lodash';

import {AuthProvider} from './AuthContext';
import {authService} from '../../services';

import {permissionService} from '../../services';

class Auth extends Component {
  state = {
    user: {},
    isLoading: false,
    isLoggedIn: false,
  };

  login = () => {
    authService.login();
  };

  loggedIn = () => {
    const authed = authService.loggedIn();

    if (!authed) {
      this.setState({ isLoggedIn: false });

      return;
    } else {
      const exp = authService.getTokenExpiration();
      const now = Date.now() / 1000;

      if (exp < now + 300) {
        authService.renewSession();
      }

      if (_.isEmpty(this.state.user)) {
        authService.getUserProfile().then(user => {
          this.setUser(user);
        });
      }

      this.setState({ isLoggedIn: true })

      return;
    }
  }

  logout = () => {
    authService.logout();
    this.setUser({});
  };

  authenticate = () => {
    authService.authenticate(this.setUser, (value) => this.setState({ isLoading: value }));
  };

  setUser = user => {
    this.setState({ user: user, isLoading: !_.isEmpty(user) ? false : true, isLoggedIn: !_.isEmpty(user) ? true : false });
  };

  protect = ({ref, privilege, methods}) => {
    const { role } = this.state.user;

    permissionService
      .authorized(role, privilege)
      .then(authorized => { 
        methods.forEach(
          method => {
            ref[method] = authorized ? ref[method] : () => {};
          }
        );
        ref.setState({authorized});
      });
  };

  render() {
    const authProviderValue = {
      ...this.state,
      login: this.login,
      loggedIn: this.loggedIn,
      authenticate: this.authenticate,
      logout: this.logout,
      protect: this.protect,
    };

    return (
      <AuthProvider value={authProviderValue}>
        {this.props.children}
      </AuthProvider>
    );
  }
}

export default Auth;
