import React from 'react';

import {curationService} from '../../services';

export const CurationContext = React.createContext();

export function withContext(Component) {
  return function ComponentWithContext(props) {
    return (
      <CurationContext.Consumer>
        {context => <Component {...props} context={context} />}
      </CurationContext.Consumer>
    );
  };
}

export default class ContextProvider extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      updateState: this.updateState.bind(this),

      user: null,

      curation: null,
      mode: 'auto',
      items: [],
    };
  }

  async componentDidMount() {
    const { curation, items } = await curationService.getActiveCuration();
    this.setState({
      curation,
      mode: curation.mode || 'auto',
      items
    });
  }

  updateState(state){
    this.setState(state);
  }

  render() {
    return (
      <CurationContext.Provider value={this.state}>
        {this.props.children}
      </CurationContext.Provider>
    );
  }
}
