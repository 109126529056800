import React from 'react';

import _ from 'lodash';
import {sectionService} from '../../services';

export const SectionContext = React.createContext();

export function withContext(Component) {
  return function ComponentWithContext(props) {
    return (
      <SectionContext.Consumer>
        {context => <Component {...props} context={context} />}
      </SectionContext.Consumer>
    );
  };
}

export default class ContextProvider extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      updateState: this.updateState.bind(this),

      getOptionList: this.getOptionList.bind(this),

      persistedOptionList: [],

      user: null,

      sections: [],

      oldValues: [],

    };
  }

  updateState(state) {
    this.setState(state);
  }

  async getOptionList() {
    const optionList = _.map(await sectionService.getFlatSectionNames(), e => _.pick(e, [ 'key', 'title', 'level' ]));
    this.setState({persistedOptionList: optionList});
    return optionList;
  }

  render() {
    return (
      <SectionContext.Provider value={this.state}>
        {this.props.children}
      </SectionContext.Provider>
    );
  }
}
