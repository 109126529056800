import decode from 'jwt-decode';
import _ from 'lodash';

import storageFactory from '../utils/storage-factory';

const AUTH_TOKEN = 'pmn_ink_token';
const localStore = storageFactory();

function isTokenValid() {
  // Checks if there is a saved token and it's still valid
  const token = getToken();
  return !!token && !isTokenExpired(token);
}

function isTokenExpired(token) {
  const decoded = decode(token);
  return decoded.exp < Date.now() / 1000; // Checking if token is expired.
}

function getTokenExpiry() {
  const token = getToken();

  if (!token) {
    return 0;
  }

  const decodedToken = decode(token);
  return decodedToken.exp;
}

function setToken(token) {
  if (!token) {
    return;
  }
  localStore.setItem(AUTH_TOKEN, token)
}

function getToken() {
  return localStore.getItem(AUTH_TOKEN);
}

function removeToken() {
  localStore.removeItem(AUTH_TOKEN);
}

function getProfile() {
  return decode(getToken());
}

function withAuthHeader(headers) {
  if (isTokenValid()) {
    const headers_ = headers ? _.cloneDeep(headers) : {};
    headers_['Authorization'] = `Bearer ${getToken()}`;
    return headers_;
  }
  return headers
}

export {
  isTokenValid,
  getTokenExpiry,
  setToken,
  getToken,
  removeToken,
  getProfile,
  withAuthHeader,
};
