// @flow
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { LiveActivitiesContext } from './context';
import JSONupdater from './pages';

import 'react-quill/dist/quill.snow.css';

const Wrapper = styled.div`
  font-family: 'Inter', sans-serif;
  padding: ${({ theme }) => theme.spacing.containerPadding};
`;

const LiveActivities: React.FC = () => {
  const jsonRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
  }, []);

  return (
    <LiveActivitiesContext.Provider value={{
      isLoading,
      setIsLoading,
      fusionBase: `https://${process.env.REACT_APP_PROJECT_ENV !== 'prod' ? 'stage.fusion.' : ''}inquirer.com`,
    }}>
      {
      process.env.REACT_APP_ENV === 'dev' ? 
      (<Wrapper>
        {jsonRef ? <JSONupdater jsonRef={jsonRef} /> : null}
        <div id="jsoneditor-react-container" ref={jsonRef} />
      </Wrapper>) : <div><br/>You must be in the dev environment to test.</div>
      }
      

    </LiveActivitiesContext.Provider>
  )
};

export default React.memo(LiveActivities);
