import React, { Fragment } from 'react';

import paginator from 'paginator';

import Pagination from 'react-bootstrap/Pagination';

class Paginator extends React.Component {

  handleClick = page => {
    return () => this.props.onChange(page);
  }

  render() {
    return (
      <Pagination className="pull-right">
        {this._build()}
      </Pagination>
    );
  }

  _build() {
    const pages = [];
    const {itemsCountPerPage, pageRangeDisplayed, totalItemsCount, activePage} = this.props;
    const attrs = new paginator(
      itemsCountPerPage,
      pageRangeDisplayed
    ).build(totalItemsCount, activePage);

    if(attrs.pages < 2) return <Fragment />;

    for (let i = attrs.first_page; i <= attrs.last_page; i++) {
      pages.push(
        <Pagination.Item
          key={i}
          active={i === activePage}
          onClick={this.handleClick(i)}
        >
          <span>{i}</span>
        </Pagination.Item>
      );
    }

    // prev page
    attrs.has_previous_page && pages.unshift(
      <Pagination.Prev key={'prev'} onClick={this.handleClick(activePage - 1)}/>
    );
    // first page
    pages.unshift(
      <Pagination.First key={'first'} onClick={this.handleClick(1)}/>
    );
    // next page
    attrs.has_next_page && pages.push(
      <Pagination.Next key={'next'} onClick={this.handleClick(activePage + 1)}/>
    );
    // last page
    pages.push(
      <Pagination.Last key={'last'} onClick={this.handleClick(attrs.total_pages)}/>
    );

    return pages;
  }

}

export default Paginator;
