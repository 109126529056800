import React, { Component } from 'react';

import { Redirect } from "react-router-dom";
import _ from "lodash";
import { withAlert } from 'react-alert';

import CurationForm from './CurationForm';
import { withContext } from './CurationContext';
import messages from './messages.json';

class NewCurationItem extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showForm: true,
    }
  }

  onCancel = () => {
    this.setState({
      showForm: false,
    });
  };

  onDone = article => {
    const { items = [], updateState } = this.props.context;
    const isArticleInItems = _.findIndex(items, (_item) => _item.story_id === article.story_id) > -1;
    if (isArticleInItems) {
      this.props.alert.error(messages.ink.curation.add.duplicated);
    } else {
      article.sequence = items.length;
      article.mode = 'manual'; // new curation item always be 'manual'
      items.push(article);
      updateState({ items });
  
      this.setState({
        showForm: false,
      });
    }
  }

  render() {
    return this.state.showForm ?
      <CurationForm
        show={this.state.showForm}
        onHide={this.onCancel}
        onDone={this.onDone}
      /> : <Redirect to='/curations' />;
  }
}

export default withContext(withAlert(NewCurationItem));

