import { getData, postData, putData, deleteData } from '../utils/fetch-api';

const baseUrl = `${process.env.REACT_APP_API_URL}`;

function createMessage(message) {
  return postData(`${baseUrl}/messages`, message);
}

function getMessage(id) {
  return getData(`${baseUrl}/messages/${id}`);
}

function getMessages({ page, paginate, isAddTimestamp }) {
  let url = `${baseUrl}/messages?page=${page}&paginate=${paginate}`;

  if (isAddTimestamp) {
    url = url.concat(`&timestamp=${(new Date()).getTime()}`);
  }

  return getData(url);
}

function getErrorMessages() {
  return getData(`${baseUrl}/messages?page=1&paginate=50&status=error`);
}

function deleteMessage(id) {
  return deleteData(`${baseUrl}/messages/${id}`);
}

function updateMessage(id, message) {
  return putData(`${baseUrl}/messages/${id}`, message);
}

function sendMessage(message) {
  return postData(`${baseUrl}/messages/send`, message).then(result => {
    return result;
  }, reason => {
    return Promise.reject(reason);
  });
}

const getPreviewImageUrl = (article) => postData(`${baseUrl}/messages/previewImage`, article);

const messageService = {
  createMessage,
  getMessage,
  getMessages,
  getErrorMessages,
  updateMessage,
  deleteMessage,
  sendMessage,
  getPreviewImageUrl,
};

export default messageService;
