import React from 'react';

import _ from 'lodash';
import { withAlert } from 'react-alert';

import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';

import messages from './messages.json';
import {permissionService} from '../../services';

class UserPermissionList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      list: [],
      disabled: true
    }
  }

  async componentDidMount() {
    const authorizedCurrentUser = await permissionService.authorized(this.props.currentRole, 'permission:update');
    const list = await permissionService.getRoleUsers().then(users => users.map((user) => {
      if (_.get(user, 'role.name', '') === 'viewer') _.set(user, 'role.name', 'registered');
      if (_.get(user, 'role.name', '') === 'manager') _.set(user, 'role.name', 'producer');
      return user;
    }));
    
    this.setState({
      list,
      disabled: !authorizedCurrentUser
    });
  }

  onChange = (roleUser, roleName) => {
    return async () => {
      try {
        const updated = await permissionService.updateUserRole(roleUser.id, roleName);
        let list = this.state.list;
        const idx = _.findIndex(list, ru => ru.id === updated.id);
        list.splice(idx, 1, updated); this.setState({list});
        this.props.alert.info(messages.ink.settings.permissions.update.success);
      } catch(err) {
        this.props.alert.error(messages.ink.settings.permissions.update.failure);
      }
    }
  }

  render() {
    return (
      <div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Role</th>
            </tr>
          </thead>
          <tbody>
            {this._renderUsers()}
          </tbody>
        </Table>
      </div>
    )
  }

  _renderUsers() {
    return _.map(this.state.list, (ru, idx) => {
      return (
        <tr key={idx}>
          <td>{idx + 1}</td>
          <td>{ru.user.name}</td>
          <td>
            {this._renderRoles(ru)}
          </td>
        </tr>
      );
    });
  }

  _renderRoles(roleuser) {
    const { disabled } = this.state;

    return (
      <Form>
        <div className="mb-3">
          {['registered', 'producer', 'superuser'].map(name => (
            <Form.Check
              key={`inline-${name}`}
              name={`radio-${roleuser.id}`}
              checked={roleuser.role.name === name}
              inline
              label={name === 'registered' ? 'Ready to activate' : name}
              type='radio'
              onChange={this.onChange(roleuser, name)}
              id={`inline-${roleuser.id}-${name}`}
              disabled={disabled}
            />
          ))}
        </div>
      </Form>
    );
  }
}

export { UserPermissionList };
export default withAlert(UserPermissionList);
