import React from "react";

import { AuthConsumer } from "./AuthContext";
import { redirectTo } from '../../utils/common';

const Callback = props => (
  <AuthConsumer>
    {({authenticate}) => {
      if (/access_token|id_token|error/.test(props.location.hash)) {
        authenticate();
      }
    }}
  </AuthConsumer>
);

export default Callback;
