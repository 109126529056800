import React, { Component } from 'react';
import packageInfo from '../../package.json';    
   
class Footer extends Component {
  render() {
    const version = packageInfo.version;
    return (
      <div className="App-footer">
        Ink. v{version} © Copyright {new Date().getFullYear()}, Philadelphia Media Network (Digital), LLC
      </div>
    );
  }
}

export default Footer;
