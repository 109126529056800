import React, { Component, Fragment } from 'react';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import Collapse from 'react-bootstrap/Collapse';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';

import _ from 'lodash';

import Protected from '../auth/Protected';

import  { confirmDialog } from '../common/ConfirmDialog';
import i18n from './messages.json';
import dateUtil from '../../utils/date-util';

const STATUS_STYLE = {
  delivered: 'success',
  draft:     'info',
  error:     'danger',
  dismissed: 'secondary',
};

class NotificationListItem extends Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false,
    }
  }

  toggleCollapse = () => {
    this.setState({open: !this.state.open});
  }

  handleAction = action => {
    let msg, act;
    if(action === 'delete') {
      msg = i18n.ink.notification.confirmation.remove
      act = this.props.handleDelete;
    } else {
      msg = i18n.ink.notification.confirmation.dismiss;
      act = this.props.handleDismiss;
    }
    return async () => {
      try {
        await confirmDialog({message: msg});
        this.toggleCollapse();
        act(this.props.item);
      } catch(err) {
        // swallow it.
      }
    };
  }

  render() {
    const {item = {}, idx} = this.props;
    const {open} = this.state;

    return (
      <Fragment key={'item' + idx}>
        <tr>
          <td>
            <div className="title">{item.title || item.body}</div>
          </td>
          <td>
            <Badge variant={STATUS_STYLE[item.status]}>
              {item.status.toUpperCase()}
            </Badge>
          </td>
          <td>{_.get(item, 'user.name', i18n.ink.notification.notAvailable)}</td>
          <td>{dateUtil.formatDateTime(item.updated_at)}</td>
          <td>
            <span className="collapse-action" onClick={this.toggleCollapse}>
              <FontAwesomeIcon icon={open ? faAngleDown : faAngleUp} />
            </span>
          </td>
        </tr>
        <tr className={open ? 'collapse-tr-show' : 'collapse-tr-hide'}>
          <td colSpan="5">
            {this._renderDetails(item, open, idx)}
          </td>
        </tr>
      </Fragment>
    );
  }

  _renderDetails(item, open, idx) {
    let {title, body, targeting} = item;

    let comments;
    try {
      comments = JSON.parse(item.comments);
    } catch {
      comments = {};
    }

    if(item.status === 'error') {
      // We got different response when send notification to mutiple devices
      // so we need handle the respone
      const fbResponse = _.get(comments, 'fbResponse', {});
      const responses = _.get(fbResponse, 'responses');
      if(Array.isArray(responses)) {
        title =  this._renderErrorTitle(fbResponse);
        body = this._renderErrorBody(responses, idx);
      } else {
        title = _.get(fbResponse, 'code');
        body = _.get(fbResponse, 'message', i18n.ink.notification.noErrorMessageFound);
      }
    }

    const tag = _.get(comments, 'tag');
    const target = _.get(targeting, 'targetType.label');
    let subTarget = _.get(targeting, 'selected', []);

    if (target === 'Device') {
      subTarget = _.truncate(subTarget, 15);
    }

    let messageId = _.get(comments, 'messageId');
    const messageIdLabel = target === 'Device' ? 'Message ID' : 'Bulk ID';
    if (_.isEmpty(messageId) === false) {
      const lastPathFCMMessageID = _.flatten(messageId)[0].match(/([^/]+)\/?$/);
      messageId = _.get(lastPathFCMMessageID, '[0]', lastPathFCMMessageID);
    }

    return (
      <Collapse in={open}>
        <Container>
          <Row>
            <Col><b>{title}</b></Col>
            <Col className='msg-body'>{body}</Col>
          </Row>
          <hr/>
          <Row>
            <Col>
              <b>Target</b>: <code>{target}</code>
              {subTarget.length > 0 ? <span>&#8594;</span> : <span></span>}
              {
                (target !== 'Device')
                ? subTarget.map((_subTarget, i) => 
                    <Fragment key={`target-value-${i}`}>
                      <span>{i > 0 && ', '}</span><code>{_subTarget}</code>
                    </Fragment>
                  )
                : <code>{subTarget}</code>
              }
            </Col>
          </Row>
          <Row>
            <Col>
              {tag && <>
                <b>Analytics Label</b>: <code>{tag}</code>
              </>}
            </Col>
          </Row>
          <Row>
            {messageId && 
              <Col><b>{messageIdLabel}</b>: <code>{messageId}</code></Col>}
          </Row>
          <Protected privilege="notification:publish">
            {this._renderActionButtons(item)}
          </Protected>
        </Container>
      </Collapse>
    );
  }

  _renderActionButtons(item) {
    if(item.status === 'delivered') {
      return <Fragment />;
    }

    const editBtn = (
      <Link to={`/notifications/edit/${item.id}`} className="btn btn-primary" size="sm">
        {i18n.ink.notification.buttons.edit}
      </Link>
    );

    const deleteBtn = (
      <Button onClick={this.handleAction('delete')} variant="warning" size="sm">
        {i18n.ink.notification.buttons.remove}
      </Button>
    );

    const dismissBtn = (
      <Button onClick={this.handleAction('dismiss')} variant="secondary" size="sm">
        {i18n.ink.notification.buttons.dismiss}
      </Button>
    );

    if(item.status === 'draft') {
      return (
        <Row>
          <Col className="pull-right">
            {deleteBtn}
          </Col>
          <Col md={1} className="pull-right">
            {editBtn}
          </Col>
        </Row>
      )
    }

    if(item.status === 'error') {
      return (
        <Row>
          <Col className="pull-right">
            {dismissBtn}
          </Col>
          <Col md={1} className="pull-right">
            {editBtn}
          </Col>
        </Row>
      )
    }
  }

  _renderErrorTitle(fbResponse) {
    return <p> {i18n.ink.notification.successCount + fbResponse.successCount} <br/>
                {i18n.ink.notification.failureCount + fbResponse.failureCount}
            </p>;
  }
  _renderErrorBody(responses, idx) {

   return responses.map(
      (res, index) => {
          return  (
            <div className="msg-body-error" key={'error-'+idx +'-'+index}>
              <h6>{ _.get(res, 'token')}</h6>
              <p className={ res.success ? 'msg-success' : 'msg-error'}> {_.get(res, 'message')  }</p>
            </div>
          )

      }
    )
  }
}

export default NotificationListItem;
