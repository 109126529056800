import React, { Component } from 'react';

import _ from 'lodash';

import { withAlert } from 'react-alert';

import NotificationForm from './NotificationForm';
import { withContext } from './NotificationContext';
import {messageService} from '../../services';
import { redirectTo } from '../../utils/common';
import messages from './messages.json';

class EditNotification extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showForm: true,
      id: _.get(props, 'match.params.id', null),
    }
  }

  async componentDidMount() {
    const { id } = this.state;
    if(!id) return;

    const message = await messageService.getMessage(id);
    let {rootTopic, updateState, sectionList} = this.props.context;
    rootTopic = this._processTargeting(rootTopic, message.targeting, sectionList);
    updateState({
      currentMessage: {
        id,
        title: {
          value: message.title,
          isValid: true,
        },
        expandTitle: {
          value: _.get(message, 'expandTitle', ''),
          isValid: true
        },
        expandBody: {
          value: _.get(message, 'expandBody', ''),
          isValid: true
        },
        body: {
          value: message.body,
          isValid: true,
        },
        directUrl: {
          value: _.get(message, 'directUrl', ''),
        },
        isIncludeImage: _.get(message, 'isIncludeImage', false),
        targeting: message.targeting,
        article: message.article,
      },
      rootTopic,
    });
  }

  handleCancel = () => {
    this.setState({
      showForm: false,
    });
  };

  handlePublish = () => {
    this.setState({
      showForm: false,
    });
  }

  handleUpdate = async (data, showSuccessAlert) => {
    try {
      const message = await this._save(data);
      if(showSuccessAlert) {
        this.handleCancel();
        this.props.alert.info(messages.ink.notification.save.success);
      }
      return message;
    } catch(err) {
      this.props.alert.error(messages.ink.notification.save.failure);
    }
  };

  render() {
    if(this.state.showForm) {
      return <NotificationForm
        show={this.state.showForm}
        onSave={this.handleUpdate}
        onHide={this.handleCancel}
        onDone={this.handlePublish}
      />
    } else {
      return redirectTo('/notifications');
    }
  }

  _save = data => {
    const {currentMessage} = this.props.context;
    return messageService.updateMessage(currentMessage.id, data);
  }

  _processTargeting(rootTopic, targeting, sectionList) {
    const type = _.get(targeting, 'targetType.label');
    // only the 'Topic' target type needs special processing
    if('Topic' !== type) return rootTopic;
    let section = this._processSectionList(sectionList, targeting.selected)

    let arr = _.map(section, item => {
      const node = _.find(rootTopic.children, child => child.key === item.key);
      return node ? _.merge(node, item) : null;
    });
    arr = _.compact(arr);

    rootTopic.children = arr;
    return rootTopic;
  }

  _processSectionList(sectionList, selected) {

    if(sectionList.length > 0) {
        sectionList.forEach(
          section => {
            section.selected = _.includes(selected,  section.key);
            this._processSectionList(section.children, selected);
          }
        )
    }

    return sectionList;
  }

}

export default withAlert(withContext(EditNotification));
