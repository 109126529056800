import React, { Component } from 'react';

class Analytics extends Component {
  render() {
    return (
      <div>Analytics Here</div>
    );
  }
}

export default Analytics;
