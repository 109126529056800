import React, { Component } from 'react';

import _ from 'lodash';

import SectionSearch from './SectionSearch';

import {sectionService} from '../../services';

import { withContext } from './SectionContext';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';

import Protected from '../auth/Protected';

class MultipleSectionList extends Component {

  constructor(props) {
    super(props);

    this.style = {
      margin: '1em',

      Button: {
        borderRadius: '50%'
      }
    }

    this.state = {
      options: []
    };
  }

  apiRefresh = async () => {
    const optionList = await this.props.context.getOptionList();
    const activeSections  = _.map(
      await sectionService.getActiveSections(),
      ({ section_key, section_name }) => {
        if (
          _.some(
            optionList,
            (section) => _.get(section, 'title', _.get(section, 'sub_section')) === section_name
          ) === true
        ) {
          return {
            editedValue: section_name || '',
            key: section_key,
            title: section_name,
            hasEdit: false,
          };
        }
      }
    );
    const sections = _.compact(activeSections);

    if (sections.length === 0) {
      sections.push({ key: '/news', title: 'News', editedValue: 'News' });
    }

    this.setState({ options: optionList });

    this.props.context.updateState({
      sections,
      oldValues: sections.slice()
    });
  };

  async componentDidMount() {
    this.apiRefresh();
  }

  addSection = () => {
    const { sections } = this.props.context;

    sections.push({ key: true, title: null });

    this.props.context.updateState({ sections });
  }

  removeSection = key => {
    return () => {
      const { sections } = this.props.context;

      sections.splice(key, 1);

      if (sections.length === 0) {
        sections.push({ key: '/news', title: 'News', editedValue: 'News', hasEdit: true });
      }

      this.props.context.updateState({
        sections
      });
    }
  }

  render() {
    const { sections } = this.props.context;

    const { options } = this.state;

    return (
      <Container>
        <Col>
          {sections.map(({ title, key, editedValue, hasEdit, refreshKey }, index) => {
            return (
              <div key={`section-table-${title}-${index}-${refreshKey===true}`} style={this.style}>
                <Row>
                  <Protected privilege="active_section:publish">
                    <Button bsstyle="primary" style={this.style.Button} onClick={this.removeSection(index)}>x</Button>
                  </Protected>
                  <Col>
                    <Protected privilege="active_section:publish" show>
                      <SectionSearch
                        index={index}
                        key={`section-search-${index}`}
                        editedValue={editedValue}
                        hasEdit={hasEdit}
                        isNew={key === true}
                        sectionName={title || ''}
                        sectionKey={key}
                        options={options} />
                    </Protected>
                  </Col>
                </Row>
              </div>
            );
          })}
          <Protected privilege="active_section:publish">
            <Button
              key='add-section-button'
              style={this.style.Button}
              onClick={this.addSection}
              disabled={_(sections).some(({ key }) => key === true)}>
              +
            </Button>
          </Protected>
        </Col>
      </Container>
    )
  }

}

export default withContext(MultipleSectionList);