import React from "react";

import Button from 'react-bootstrap/Button'

import { AuthConsumer } from "./AuthContext";

const Logout = () => (
  <AuthConsumer>
    {({ logout, user }) => (
      <div className='d-flex justify-content-center text-nowrap'>
        <Button
          onClick={logout}
          variant="secondary"
          size="sm">Log out
        </Button>
      </div>
    )}
  </AuthConsumer>
);

export default Logout;
