import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import '../../styles/Notification.css';
import Can from '../auth/Can';
import Unauthorized from '../auth/Unauthorized';
import { AuthConsumer } from '../auth/AuthContext';
import Protected from '../auth/Protected';
import NotificationList from './NotificationList';
import NewNotification from './NewNotification';
import EditNotification from './EditNotification';
import { RouteWithSubRoutes } from '../../routes';
import ContextProvider from './NotificationContext';
import i18n from './messages.json';

class Notifications extends Component {

  render() {
    return (
        <AuthConsumer>
          {({user}) =>
              <Can
                role={user.role}
                perform="notification:view"
                yes={() => this._renderNotificationHome()}
                no={() => <Unauthorized />}
              />
          }
        </AuthConsumer>
    );
  }

  _renderNotificationHome() {
    const { routes } = this.props;

    return (
      <ContextProvider>
        <div className="Notif-container">
          <Container>

            <Protected privilege="notification:publish" customBlankClassName="btn">
              <Link to="//datastudio.google.com/embed/reporting/1ZirGF73BE7sWN2CAWtEHAu5gpZqLHppB/page/23Bh"
                className="Notif-reports-btn"
                target="_blank">
                  <b>{i18n.ink.notification.buttons.reports}</b>
                  <img src="images/external-link.png" className="Notif-reports-icon" alt="Ga data studio"/>
              </Link>

              <NewNotification />
            </Protected>

            <Row className="error-log">
              <Col>
                <NotificationList type="errors" />
              </Col>
            </Row>

            <Row className="activity-log">
              <Col>
                <NotificationList type="activity-log" open={true} />
              </Col>
            </Row>
          </Container>
          {routes.map((route, idx) => <RouteWithSubRoutes key={idx} {...route} />)}
        </div>
      </ContextProvider>
    );
  }

}

export {NewNotification, EditNotification};
export default Notifications;
