import React, { Component } from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import _ from 'lodash';

import SearchArticle from '../common/SearchArticle';
import Targeting from './Targeting';
import { withContext } from './NotificationContext';
import i18n from './messages.json';
import { messageService } from '../../services';
import { progressDialog } from '../common/ProgressDialog';
import { CONSTANTS } from '../../utils/common';
import { processTopicTargeting } from '../../utils/topic-util';
import { validURL } from '../../utils/url-helper';

import './Notification.css';

const ArticleType = 'Article';
const DirectUrlType = 'DirectURL';

class NotificationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isRenderPreview: false,
      isIncludeImage: false,
      previewImageUrl: '',
      typeNotification: ArticleType,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (_.isEmpty(_.get(nextProps, 'context.currentMessage.directUrl.value', '')) === false) {
      this.setState({ typeNotification: DirectUrlType });
    }
  }

  _preprocessTopicTargeting = (targeting) => {
    const targetingType = _.get(targeting, 'targetType.label', '');
    if (targetingType !== 'Topic') {
      return targeting;
    }

    // in case we use the draft or error box to send again => traverseTopicTree will crash
    // because tageting.sected = ['topic1', 'topic2' ...] => do not have chilren =>
    // so for the hot fix, I much cheat here
    const topics = targeting.selected;

    if (Array.isArray(topics) && topics.length > 0 && _.isString(topics[0])) {
      return targeting;
    }

    const traverseTopicTree = (topics, acc = []) => {
      topics.forEach((topic) => {
        const { key, selected, children } = topic;
        if (selected) acc.push(key);
        traverseTopicTree(children, acc);
      });
      return acc;
    };

    targeting.selected = traverseTopicTree(topics);
  };

  handleTitleChanged = (event) => {
    const { value } = event.target;

    const isValid = value.length <= 64;
    const { title } = i18n.ink.notification;
    const error = isValid ? '' : title.overMaxLength;

    const { currentMessage, updateState } = this.props.context;
    currentMessage.title = { value, isValid, error };
    updateState({ currentMessage });
  };

  handleBodyChanged = (event) => {
    const { value } = event.target;
    const { body } = i18n.ink.notification;
    const trimedValue = _.trim(value);

    const isValid = trimedValue.length > 0 && trimedValue.length <= 256;
    const error = isValid
      ? ''
      : trimedValue.length > 256
      ? body.overMaxLength
      : body.required;

    const { currentMessage, updateState } = this.props.context;
    currentMessage.body = { value, isValid, error };
    updateState({ currentMessage });
  };

  handleExpandTitleChanged = (event) => {
    const { value } = event.target;
    const isValid = value.length <= 64;
    const { expandTitle } = i18n.ink.notification;
    const error = isValid ? '' : expandTitle.overMaxLength;
    const { currentMessage, updateState } = this.props.context;
    currentMessage.expandTitle = { value, isValid, error };

    updateState({ currentMessage });
  };

  handleExpandBodyChanged = (event) => {
    const { value } = event.target;
    const { expandBody } = i18n.ink.notification;
    const isValid = value.length <= 256;
    const error = isValid ? '' : expandBody.overMaxLength;
    const { currentMessage, updateState } = this.props.context;
    currentMessage.expandBody = { value, isValid, error };

    updateState({ currentMessage });
  };

  handleIsIncludeImageChange = (event) => {
    const { checked } = event.target;
    const { currentMessage, updateState } = this.props.context;
    currentMessage.isIncludeImage = checked;

    updateState({ currentMessage });
  };

  handleArticleChanged = (article) => {
    const { currentMessage, updateState } = this.props.context;
    currentMessage.article = article;

    updateState({ currentMessage });
  };

  handleSave = (showSuccessAlert = true) => {
    if (!this.props.onSave) return;

    this.setState({ isRenderPreview: false });
    const { currentMessage } = this.props.context;
    const customizedTargeting = Object.assign({}, currentMessage.targeting);
    this._preprocessTopicTargeting(customizedTargeting);

    const data = {
      title: currentMessage.title.value,
      body: _.trim(currentMessage.body.value),
      article: currentMessage.article,
      isIncludeImage: _.isEmpty(_.get(currentMessage, 'article.image_url'))
        ? false
        : currentMessage.isIncludeImage,
      expandTitle: currentMessage.expandTitle.value,
      expandBody: _.trim(currentMessage.expandBody.value),
      targeting: customizedTargeting,
      directUrl: currentMessage.directUrl.value,
      status: 'draft',
    };

    return this.props.onSave(data, showSuccessAlert);
  };

  handleSend = async () => {
    document.getElementsByClassName('_modal-form_')[0].style.display = 'none';

    let msg = await this.handleSave(false);

    const processResovled = (response) => {
      this.props.onDone(msg, response);
    };

    await progressDialog({
      message: i18n.ink.notification.sending,
      promise: messageService.sendMessage(msg),
      successButtonLabel: i18n.ink.notification.buttons.seeNotification,
      errorButtonLabel: i18n.ink.notification.buttons.seeError,
      processResovled: processResovled,
    });
  };

  handlePreview = () => {
    const { currentMessage } = this.props.context;
    const isIncludeImage = _.get(currentMessage, 'isIncludeImage', false);

    if (_.isEmpty(currentMessage.article) === true) {
      currentMessage.expandTitle = {
        value: '',
        isValid: true,
        error: '',
      };

      currentMessage.expandBody = {
        value: '',
        isValid: true,
        error: '',
      }
    }

    if (isIncludeImage === true) {
      const article = _.get(this.props, 'context.currentMessage.article', false);

      messageService.getPreviewImageUrl(article).then(
        data => {
          this.setState({
            previewImageUrl: _.get(data, 'cropImageUrls.ratio_2x1', _.get(data, 'imageUrl'))
          })
        }
      );
    }

    this.setState({
      isRenderPreview: true,
    });
  };

  handleNotificationTypeChange = (name) => {
    this.setState({ typeNotification: name });
    const { currentMessage, updateState } = this.props.context;

    if (_.isEmpty(currentMessage.article) === false) {
      currentMessage.article = null;
    }

    if (_.isEmpty(currentMessage.directUrl.value) === false) {
      currentMessage.directUrl.value = '';
    }

    currentMessage.expandBody.value = '';
    currentMessage.expandTitle.value = '';
    updateState({ currentMessage });
  };

  handleDirectUrlChange = (event) => {
    const { value } = event.target;

    const { currentMessage, updateState } = this.props.context;
    currentMessage.directUrl = { value };

    updateState({ currentMessage });
  };

  _isRenderRichPushNotification = (currentMessage, isIncludeImage) => {
    return (
      _.trim(currentMessage.expandTitle.value) !== '' ||
      _.trim(currentMessage.expandBody.value) !== '' ||
      (_.isEmpty(_.get(currentMessage, 'article.image_url')) === false &&
        isIncludeImage)
    );
  };

  renderModalContent = (
    currentMessage,
    targeting,
    shouldShowOptionalText,
    isArticleValid,
    isIncludeImage
  ) => {
    const target = _.get(targeting, 'targetType.label', '');
    const subTarget = _.get(targeting, 'selected');
    const { previewImageUrl } = this.state;

    return this.state.isRenderPreview ? (
      <Form>
        <p className='targeting-title'>
          <span className='font-weight-bold'> Targeting: </span> {target}
          {subTarget && (
            <span>
              {' > '}
              {target === 'Topic'
                ? _.chain(processTopicTargeting(targeting))
                    .compact()
                    .join(', ')
                    .value()
                : _.truncate(subTarget.join(', '), 30)}
            </span>
          )}
        </p>
        <div className='margin-bottom'>
          <h5 className='title-preview'>
            <span>Compressed (closed) &nbsp;</span>
          </h5>
          <div className='normal-notification-preview'>
            <p className='font-weight-bold'> {currentMessage.title.value} </p>
            <p> {currentMessage.body.value} </p>
          </div>
        </div>
        {this.state.typeNotification === DirectUrlType && (
          <div className='margin-bottom'>
            <h5 className='title-preview'>
              <span>Direct Url</span>
            </h5>
            <div className='normal-notification-preview'>
              <a
                href={currentMessage.directUrl.value}
                className='font-weight-bold text-break'
              >
                {currentMessage.directUrl.value}
              </a>
            </div>
          </div>
        )}
        {this._isRenderRichPushNotification(currentMessage, isIncludeImage) && (
          <div className='margin-bottom'>
            <h5 className='title-preview'>
              <span>Expaned (opened) &nbsp;</span>
            </h5>
            <div className='normal-notification-preview'>
              {_.isEmpty(_.get(currentMessage, 'article.image_url')) ===
                false &&
                isIncludeImage && (
                  <>
                    <p className='targeting-title' style={{textAlign: 'left', marginTop: '15px'}}>
                      <span className='font-weight-bold'> iOS Preview</span>
                    </p>
                    <div className="image-box">
                      <img
                        src={previewImageUrl}
                        alt={currentMessage.title.value}
                        style={{
                          maxWidth: '300px',
                          maxHeight: '150px',
                          margin: 'auto',
                          display: 'block'
                        }}
                      />
                    </div>
                    <p className='targeting-title' style={{textAlign: 'left', marginTop: '15px'}}>
                      <span className='font-weight-bold'> Android Preview</span>
                    </p>
                    <div className="image-box" style={{height: '120px'}}>
                      <img
                        src={previewImageUrl}
                        alt={currentMessage.title.value}
                        style={{
                          margin: 'auto',
                          display: 'block',
                          objectFit: 'cover', /* Do not scale the image */
                          objectPosition: 'center', /* Center the image within the element */
                          height: '100px',
                          width: '100%',
                        }}
                      />
                    </div>
                  </>

                )}
              <p> {currentMessage.expandTitle.value} </p>
              <p className='small-text'> {currentMessage.expandBody.value} </p>
            </div>
          </div>
        )}
      </Form>
    ) : (
      <Form>
        <div className='mb-3'>
          {[ArticleType, DirectUrlType].map((name) => (
            <Form.Check
              key={`inline-${name}`}
              name={`radio-${name}`}
              checked={this.state.typeNotification === name}
              inline
              label={
                name === ArticleType
                  ? `${i18n.ink.notification.article.label}${
                      shouldShowOptionalText ? ' (Optional)' : ''
                    }`
                  : name
              }
              type='radio'
              onChange={() => this.handleNotificationTypeChange(name)}
              id={`inline-${name}`}
            />
          ))}
        </div>
        {this.state.typeNotification === ArticleType ? (
          <Form.Group controlId='formArticle'>
            <SearchArticle
              updateSelected={this.handleArticleChanged}
              selected={currentMessage.article}
              isValid={isArticleValid}
            />
            {isArticleValid === false && (
              <div className='invalid-feedback d-block'>
                {i18n.ink.notification.article.required}
              </div>
            )}
          </Form.Group>
        ) : (
          <Form.Group controlId='formDirectUrl'>
            <Form.Control
              value={currentMessage.directUrl.value}
              onChange={this.handleDirectUrlChange}
              placeholder={i18n.ink.notification.directUrl.placeholder}
              isInvalid={validURL(currentMessage.directUrl.value) === false}
            />
            <Form.Control.Feedback type='invalid'>
              {i18n.ink.notification.directUrl.invalidUrl}
            </Form.Control.Feedback>
          </Form.Group>
        )}

        {_.isEmpty(_.get(currentMessage, 'article.image_url')) === false && (
          <Form.Group controlId='incluseImage'>
            <Form.Check
              type='checkbox'
              label='Include image (optional)'
              onChange={this.handleIsIncludeImageChange}
              checked={currentMessage.isIncludeImage}
            />
          </Form.Group>
        )}
        <Form.Group controlId='formTitle'>
          <Form.Label>{i18n.ink.notification.title.label}</Form.Label>
          <Form.Control
            isInvalid={!currentMessage.title.isValid}
            value={currentMessage.title.value}
            onChange={this.handleTitleChanged}
            placeholder={i18n.ink.notification.title.placeholder}
          />
          <Form.Control.Feedback type='invalid'>
            {currentMessage.title.error}
          </Form.Control.Feedback>
          <Form.Text className='text-muted word-count'>
            {currentMessage.title.value.length} / 64
          </Form.Text>
        </Form.Group>

        <Form.Group controlId='formBody'>
          <Form.Label>{i18n.ink.notification.body.label}</Form.Label>
          <Form.Control
            as='textarea'
            rows='3'
            isInvalid={!currentMessage.body.isValid}
            value={currentMessage.body.value}
            onChange={this.handleBodyChanged}
            placeholder={i18n.ink.notification.body.placeholder}
          />
          <Form.Control.Feedback type='invalid'>
            {currentMessage.body.error}
          </Form.Control.Feedback>
          <Form.Text className='text-muted word-count'>
            {currentMessage.body.value.length} / 256
          </Form.Text>
        </Form.Group>

        {currentMessage.article && (
          <>
            <Form.Group controlId='formTitleExpand'>
              <Form.Label>{i18n.ink.notification.expandTitle.label}</Form.Label>
              <Form.Control
                isInvalid={!currentMessage.expandTitle.isValid}
                value={currentMessage.expandTitle.value}
                onChange={this.handleExpandTitleChanged}
                placeholder={i18n.ink.notification.expandTitle.placeholder}
              />
              <Form.Control.Feedback type='invalid'>
                {currentMessage.expandTitle.error}
              </Form.Control.Feedback>
              <Form.Text className='text-muted word-count'>
                {currentMessage.expandTitle.value.length} / 64
              </Form.Text>
            </Form.Group>
            <Form.Group controlId='formBodyExpand'>
              <Form.Label>{i18n.ink.notification.expandBody.label}</Form.Label>
              <Form.Control
                as='textarea'
                rows='3'
                isInvalid={!currentMessage.expandBody.isValid}
                value={currentMessage.expandBody.value}
                onChange={this.handleExpandBodyChanged}
                placeholder={i18n.ink.notification.expandBody.placeholder}
              />
              <Form.Control.Feedback type='invalid'>
                {currentMessage.expandBody.error}
              </Form.Control.Feedback>
              <Form.Text className='text-muted word-count'>
                {currentMessage.expandBody.value.length} / 256
              </Form.Text>
            </Form.Group>
          </>
        )}

        <Form.Group controlId='formTargeting' className='target-input'>
          <Targeting />

          <Form.Control
            isInvalid={!targeting.isValid}
            style={{ display: 'none' }}
          />
          <Form.Control.Feedback type='invalid'>
            {_.compact(targeting.selected).length < CONSTANTS.max_token_num
              ? i18n.ink.notification.targeting.required
              : i18n.ink.notification.targeting.maxTokens}
          </Form.Control.Feedback>
        </Form.Group>
      </Form>
    );
  };

  render() {
    const { currentMessage } = this.props.context;
    const { targeting, isIncludeImage } = currentMessage;
    const { isValid, selected } = targeting;
    const shouldShowOptionalText =
      isValid === true && _.indexOf(selected, 'Web') === -1;
    const isArticleValid =
      shouldShowOptionalText === true ||
      _.isEmpty(currentMessage.article) === false;
    const { isRenderPreview } = this.state;

    return (
      <Modal
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop={'static'}
        onHide={this.props.onHide}
        show={this.props.show}
        className='_modal-form_'
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            {isRenderPreview
              ? i18n.ink.notification.dialog.previewTitle
              : i18n.ink.notification.dialog.title}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this.renderModalContent(
            currentMessage,
            targeting,
            shouldShowOptionalText,
            isArticleValid,
            isIncludeImage
          )}
        </Modal.Body>

        <Modal.Footer>
          {isRenderPreview ? (
            <Button
              variant='light'
              onClick={() => {
                this.setState({ isRenderPreview: false });
              }}
            >
              {i18n.ink.notification.buttons.edit}
            </Button>
          ) : (
            <Button
              variant='light'
              onClick={() => {
                this.setState({ isRenderPreview: false });
                this.props.onHide();
              }}
            >
              {i18n.ink.notification.buttons.cancel}
            </Button>
          )}

          <Button
            variant='secondary'
            disabled={!this._isValid() || !isArticleValid}
            onClick={this.handleSave}
          >
            {currentMessage.id
              ? i18n.ink.notification.buttons.update
              : i18n.ink.notification.buttons.save}
          </Button>
          {isRenderPreview ? (
            <Button
              variant='primary'
              disabled={!this._isValid() || !isArticleValid}
              onClick={this.handleSend}
            >
              {i18n.ink.notification.buttons.send}
            </Button>
          ) : (
            <Button
              variant='primary'
              disabled={!this._isValid() || !isArticleValid}
              onClick={this.handlePreview}
            >
              {i18n.ink.notification.buttons.preview}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }

  _isValid() {
    let isValidUrl = true;

    const { body, targeting, directUrl } = this.props.context.currentMessage;

    if (this.state.typeNotification === DirectUrlType) {
      isValidUrl = validURL(directUrl.value);
    }

    return body.isValid && targeting.isValid && isValidUrl;
  }
}

export default withContext(NotificationForm);
