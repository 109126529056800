import React, { Component } from 'react';
import _ from 'lodash';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';

import i18n from './messages.json';
import { withContext } from './NotificationContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlus} from '@fortawesome/free-solid-svg-icons';
import {CONSTANTS} from '../../utils/common';
class AddTokensBox extends Component {

  state = {
    tokens: []
  };

  async componentDidMount() {

  const selected = _.cloneDeep(this.props.context.currentMessage.targeting.selected) || [];

  if(selected !== []) {
    const {setStateAsync} = this.props.context;
    await setStateAsync({tokens: selected});
  }
    this.setState({tokens: selected});
  }

  handleCancel = () => {
    this.props.onHide();
  };

  handleDone = async () => {
    const {setStateAsync} = this.props.context;
    let {tokens} = {...this.state};
    if( this._isDuplicatedTokensArray(tokens)) {
      this.setState({isDuplicatedTokens: true});
    } else {
      tokens= _.compact(tokens);
      await setStateAsync({tokens});
      this.props.onDone();
    }
  }

  handleAddNewTokenBox = () => {

    let {tokens} = {...this.state};

    // if user don't fill any box we don't allow them add more
    // don't allow user enter > max_token_numer
    if( (tokens[tokens.length -1] !== '') && tokens.length < CONSTANTS.max_token_num ) {
        tokens.push('');
        this.setState({tokens}, () => {
          // just a trick, auto scoll to the bottom of the div when we add more token
          var elem2 = document.getElementById('parent-input-tokens-box');
          elem2.scrollTop =   elem2.scrollHeight + 20;
        });
    }
  }

  handleOnChangeToken = (e, idx) => {
    let {tokens} = {...this.state};
    const value = _.get(e,'target.value', '');
    tokens[idx] = _.trim(value);
    this.setState({tokens});
  }
  onDeleteToken = index => {
    let {tokens} = {...this.state};
    tokens = tokens.filter( (t, idx) => idx !== index);
    this.setState({tokens});
  }
  render() {

    const {tokens} = {...this.state};
    return (
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop={'static'}
        onHide={this.props.onHide}
        show={this.props.show}
        dialogClassName='add-token-modal'
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          {i18n.ink.notification.addTokenBox.title}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="limit-input-token-box" id='parent-input-tokens-box'>
             {this._renderTokenInputs(tokens)}
          </div>
          {this._renderAddMoreButton()}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="light" id='on-hide-add-tokens-box' onClick={this.handleCancel}>
            {i18n.ink.notification.buttons.cancel}
          </Button>
          <Button variant="primary" disabled={this._isDuplicatedTokensArray(tokens)} id='on-done-add-tokens-box' onClick={this.handleDone}>
            {i18n.ink.notification.buttons.done}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  _renderTokenInputs(tokens) {

    const xButtonStyle = {
        textDecoration: 'none',
        color: '#d1d4dc'
    }
    if( tokens.length === 0) tokens.push('');

    return (
        tokens.map( (token, idx) =>
            <InputGroup className="mb-3 margin-left-down-10" key={idx}>
            <Button variant="link" id='remove-token-field' style={xButtonStyle} onClick={ () => this.onDeleteToken(idx)}>
            <FontAwesomeIcon icon={faTimes} />
            </Button>
            <FormControl  isInvalid={ this._isDuplicatedToken(token, tokens)} className= "border-input" value={token}  onChange={ e =>this.handleOnChangeToken(e, idx)}/>
             <FormControl.Feedback type="invalid" className="error-duplicated">
                {i18n.ink.notification.addTokenBox.duplicatedToken}
             </FormControl.Feedback>
           </InputGroup>
        )
    )
  }

  _renderAddMoreButton() {

    const addMoreButtonStyle = {
        borderRadius: '38px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '20px',
        display: 'block'
    };
    return (
       <Button variant="outline-secondary" style={addMoreButtonStyle} className="add-more-token-button" id='add-new-token-field' onClick={this.handleAddNewTokenBox}>
       <FontAwesomeIcon icon={faPlus} style={{height: '10px', display:'block'}}/>
       </Button>
    )
  }

  // this function is used for check the current tokens array has duplicated element or not
  _isDuplicatedTokensArray(tokens) {
    return _.uniq(tokens).length !== tokens.length;
  }

  // this function is used for check the token is duplicated or not
  _isDuplicatedToken(token, tokens) {
    return _.filter(tokens, t => t === token).length !== 1;
  }
}

export default withContext(AddTokensBox);
