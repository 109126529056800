import React, { Component } from 'react';

import _ from 'lodash';

import { withAlert } from 'react-alert'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import {curationService} from '../../services';
import { withContext } from './CurationContext';

import messages from './messages.json';

class ActionBar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  onPublish = async () => {
    try {
      this.setState({isLoading: true});
      await this._handleSave();
      this.props.alert.info(messages.ink.curation.publish.success);
      this.setState({isLoading: false});
    } catch(err) {
      this.props.alert.error(messages.ink.curation.publish.failure);
      this.setState({isLoading: false});
    }
  };

  onCancel = async () => {
    const {curation, items} = await curationService.getActiveCuration();
    this.props.context.updateState({
      curation,
      mode: curation.mode || 'auto',
      items
    });
  }

  render() {
    const { items } = this.props.context;

    return (
      <Row className="action-bar">
        <Col></Col>
        <Col className="pull-right">
          <Button
            variant="secondary"
            onClick={this.onCancel}
            size="sm">Cancel
          </Button>
        </Col>
        <Col md={2} className="pull-right">
          <Button
            onClick={this.onPublish}
            disabled={this.state.isLoading || items.length === 0}
            variant="primary"
            size="sm">Publish
          </Button>
        </Col>
      </Row>
    );
  }

  async _handleSave() {
    const { curation, items, mode, updateState } = this.props.context;

    // create the active curation
    let data = {
      mode,
      is_active: true,
      is_published: true,
      description: 'Active Curation',
      items: this._prepareItems(items),
    };
    const model = await curationService.createCuration(data);

    if (curation && curation.id) { // update the history
      await curationService.updateCuration(curation.id, {is_active: false, description: 'History Curation'});
    }

    updateState({curation: model});
  }

  _prepareItems = items => {
    return _.chain(items)
      .map((item, idx) => {
        _.unset(item, 'id');
        _.set(item, 'sequence', idx);
        return item;
      })
      .filter(item => item.mode === 'manual')
      .value();
  };
}

export default withContext(withAlert(ActionBar));
