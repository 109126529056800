import React from 'react';

import Can from '../auth/Can';
import Unauthorized from '../auth/Unauthorized';
import { AuthConsumer } from '../auth/AuthContext';
import DemoArticlesForm from './DemoArticleForm';

const DemoArticles = () => {
  return (
    <AuthConsumer>
      {({ user }) => (
        <Can
          role={user.role}
          perform='curation:view'
          yes={() => <DemoArticlesForm />}
          no={() => <Unauthorized />}
        />
      )}
    </AuthConsumer>
  );
};

export default DemoArticles;
