// @flow
import { createContext, useContext } from 'react';

export type ILiveActivitiesContext = {
  isLoading: boolean;
  setIsLoading: (state: boolean) => void;
  fusionBase: string;};

export const LiveActivitiesContext = createContext<ILiveActivitiesContext>({
  isLoading: false,
  setIsLoading: (_state: boolean) => {},
  fusionBase: ''
});

export const useLiveActivitiesContext = () => useContext(LiveActivitiesContext);
