import React, { Component } from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import SearchArticle from '../common/SearchArticle';

class CurationForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selected: null,
    };
  }

  done = () => {
    const { onDone } = this.props;
    if(onDone) {
      onDone(this.state.selected);
    }
  }

  render() {
    return (
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop={'static'}
        onHide={this.props.onHide}
        show={this.props.show}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Search Article
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formTitle">
              <Form.Label>Keyword</Form.Label>
              <SearchArticle
                keepFocus={true}
                updateSelected={this._updateSelected}/>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light"
            onClick={this.props.onHide}>Cancel</Button>
          <Button variant="primary"
            disabled={!this.state.selected}
            onClick={this.done}>Done
          </Button>

        </Modal.Footer>
      </Modal>
    );
  }

  _updateSelected = selected => {
    this.setState({
      selected,
    })
  }

}

export default CurationForm;

