import React from 'react';
import Toast from 'react-bootstrap/Toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faExclamationTriangle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const AlertTemplate = ({ message, options, style, close }) => {
  return (
    <Toast
      className={`mr-2 mt-2 bg-${options.type === 'error' ? 'danger' : options.type}`}
      style={{
        minWidth: 350
      }}
    >
      <Toast.Header className='p-2 ' closeButton={false}>
        {options.type === 'info' && <FontAwesomeIcon icon={faInfoCircle} />}
        {options.type === 'success' &&  <FontAwesomeIcon icon={faCheckCircle} />}
        {options.type === 'error' && <FontAwesomeIcon icon={faExclamationTriangle} />}
        <strong className='me-auto ml-2'>Alert</strong>
      </Toast.Header>
      <Toast.Body className='px-2 py-4 text-white'>
        {message}
      </Toast.Body>
    </Toast>
  )
}

export default AlertTemplate;
