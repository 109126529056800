import React, { Component } from 'react';

import Switch from 'react-switch';
import { ScaleLoader } from 'react-spinners';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Protected from '../auth/Protected';

import { withContext } from './CurationContext';
import {curationService} from '../../services';

import './ModeBar.css';

const staticStyleProps = {
  boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.6)",
  activeBoxShadow: "0px 0px 1px 10px rgba(0, 0, 0, 0.2)",
  onColor: "#86d3ff",
  onHandleColor: "#2693e6",
  uncheckedIcon: false,
  checkedIcon: false,
}

class ModeBar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  onChange = async checked => {
    this.setState({loading: true});

    const mode = checked ? 'auto' : 'manual';
    let items = await curationService.getAutoCuratedStories(mode);
    this.props.context.updateState({ mode, items });

    this.setState({loading: false});
  };

  render() {
    const { mode } = this.props.context;
    return (
      <Row className={'ModeBar'}>
        <Col>
          <h4>Top Story Carousel</h4>
        </Col>
        <Protected privilege="curation:publish">
          <Col className='pull-right'>
            <span>
              <ScaleLoader
                color={'#86D3FF'}
                height={10}
                width={4}
                margin={'8px 2px'}
                loading={this.state.loading}
              />
            </span>
            <span>Manual</span>
            <Switch
              className='toggleSwitch'
              checked={mode !== 'manual'}
              onChange={this.onChange}
              disabled={this.state.loading}
              {...staticStyleProps}
            />
            <span>Auto</span>
          </Col>
        </Protected>
      </Row>
    );
  }
}

export default withContext(ModeBar);
