import React, { Component } from 'react';

import _ from 'lodash';

import { withAlert } from 'react-alert';
import Button from 'react-bootstrap/Button';
import i18n from './messages.json';

import NotificationForm from './NotificationForm';
import { withContext } from './NotificationContext';
import {messageService} from '../../services';
import messages from './messages.json';

class NewNotification extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showForm: false,
    }
  }

  componentDidMount() {
    let {currentMessage, rootTopic, updateState} = this.props.context;

    const type = _.get(currentMessage, 'targeting.targetType.label');
    // only the 'Topic' target type needs special processing
    if('Topic' !== type) return;

    rootTopic = this._processTargeting(rootTopic, currentMessage.targeting);
    updateState({rootTopic});
  }

  handleCancel = () => {
    this.setState({
      showForm: false,
    });
  };

  handlePublish = async (message, response) => {
    let isError = false;

    if (_.isEmpty(response.responses) === false) {
      if (response.failureCount > 0) {
        isError = true;
      }
    } else if (_.isArray(response) === false) {
      isError = true;
    }

    await this.props.context.getMessages(1, false, message, isError);
    this.setState({
      showForm: false,
    });
  }

  handleSave = async (data, showSuccessAlert) => {
    try {
      const message = await this._save(data);
      if(showSuccessAlert) {
        this.handleCancel();
        await this.props.context.getMessages(1, false, message);
        this.props.alert.info(messages.ink.notification.save.success);
      }
      return message;
    } catch(err) {
      this.props.alert.error(messages.ink.notification.save.failure);
    }
  };

  render() {

    return (
      <>
      <Button className="Notif-compose-btn btn btn-primary" onClick={() => {this.setState({showForm: true})}}>
        {i18n.ink.notification.buttons.compose}
      </Button>

      <NotificationForm
        show={this.state.showForm}
        onSave={this.handleSave}
        onHide={this.handleCancel}
        onDone={this.handlePublish}
      />
      </>
    )

  }

  _save = data => {
    if(data.id) { // when update the firebase sending result, it is a persisted entity
      return messageService.updateMessage(data.id, data);
    }

    return messageService.createMessage(data);
  }

  _processTargeting(rootTopic, targeting) {
    let arr = _.map(targeting.selected, item => {
      const node = _.find(rootTopic.children, child => child.label === item.label);
      return node ? _.merge(node, item) : null;
    });
    arr = _.compact(arr);

    rootTopic.children = arr;
    return rootTopic;
  }

}

export default withContext(withAlert(NewNotification));
