import _ from 'lodash';

import { postData, getData, putData } from '../utils/fetch-api';
import storyService from './story-service';
import { story2CurationItem } from '../utils/common';

const baseUrl = `${process.env.REACT_APP_API_URL}`;

function createCuration(curation) {
  return postData(`${baseUrl}/curations`, curation);
}

async function getAutoCuratedStories(mode = 'auto') {
  let topStories = await storyService.getCuratedStories();
  let items = _.get(topStories, 'data.feeds.top_stories', []);

  if (mode === 'auto') {
    const mainSiteCuratedStories = await storyService.getAutoCuratedStories();
    topStories = await storyService.getStoriesByIds({
      ids: _.map(mainSiteCuratedStories, 'id'),
    });
    items = _.get(topStories, 'articles', []);
  }

  items = _.map(items, story => {
    let item = story2CurationItem(story);
    _.set(item, 'mode', mode);
    _.set(item, 'is_anchored', 'auto' === mode); // TODO: we have to figure out a way to use is_anchored...
    return item;
  });
  return items
}

async function getCuratedStories(curation) {
  const ids = _.map(curation.items, item => item.story_id);
  if (_.isEmpty(ids)) return [];

  const stories = await storyService.getStoriesByIds({ids});
  let items = _.map(stories.articles, story => story2CurationItem(story));

  // merge the story to the curation item, ie. the story was updated etc.
  items = _.map(items, item => {
    let curationItem = _.find(curation.items, ci => ci.story_id === item.story_id);
    curationItem = _.merge(curationItem, item);
    _.set(curationItem, 'mode', 'manual');
    return curationItem;
  });

  return items;
}

async function getActiveCuration() {
  let autoItems = [], curatedItems = [];

  let curation = await getData(`${baseUrl}/curations/active`);
  if (!curation || _.isEmpty(curation)) { // first time, no curation created
    curation = {
      mode: 'auto',
    }
  }

  if (curation.mode === 'auto') {
    autoItems = await getAutoCuratedStories();
  }
  curatedItems = await getCuratedStories(curation);

  const items = Array.from(autoItems);
  _.forEach(curatedItems, ci => {
    items.splice(ci.sequence, 0, ci);
  });

  return {curation, items};
}

function updateCuration(id, curation) {
  return putData(`${baseUrl}/curations/${id}`, curation);
}

const curationService = {
  createCuration,
  getActiveCuration,
  updateCuration,
  getCuratedStories,
  getAutoCuratedStories,
};

export default curationService;
