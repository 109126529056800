import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

class ConfirmDialog extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showDialog: true,
    }
  }

  handleCancel = () => {
    this.setState({
      showDialog: false,
    });

    this.props.reject(false);
  };

  handleConfirm = () => {
    this.setState({
      showDialog: false
    });
    this.props.resolve(true);
  }

  render() {
    const { title, message } = this.props;

    return (
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop={'static'}
        show={this.state.showDialog}
        onHide={this.handleCancel}
      >
        <Modal.Header>
          <Modal.Title>{title || 'Alert'}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{message || 'Do you want to confirm these changes?'}</p>
          {this?.props?.children}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleCancel}>No</Button>
          <Button variant={this.props?.confirmVariant || 'primary'} onClick={this.handleConfirm}>{this.props?.okText || 'Yes'}</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export const confirmDialog = props => {
  const mountDiv = document.body.appendChild(document.createElement('div'));
  const cleanup = () => ReactDOM.unmountComponentAtNode(mountDiv);

  const promise = new Promise((resolved, rejected) => {
    ReactDOM.render(
      <ConfirmDialog
        reject={rejected}
        resolve={resolved}
        {...props}
      />,
      mountDiv
    );
  });

  return promise
    .then( result => {}, reject => Promise.reject(reject) )
    .finally( () => cleanup() );
}

export default ConfirmDialog;
