import React, { useEffect, useState } from 'react';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _split from 'lodash/split';
import _join from 'lodash/join';
import _remove from 'lodash/remove';
import _trim from 'lodash/trim';
import {
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { withAlert } from 'react-alert';

import storyService from '../../services/story-service';

const DemoArticlesForm = ({ alert }) => {
  const [articleIdsText, setArticleIdsText] = useState('');
  const [listIds, setListIds] = useState([]);
  const [demoStories, setDemoStories] = useState([]);
  const [isDisableAddButton, setIsDisableAddButton] = useState(false);
  const [isDisableDeleteButton, setIdDisableDeleteButton] = useState(false);

  useEffect(() => {
    onFetchingData();
  }, []);

  const onFetchingData = async () => {
    const demoArticlesIds = await storyService.getDemoArticles();
    const originalIds = _get(demoArticlesIds, 'data.getDemoArticleData', []);

    if (originalIds.length === 0) {
      setListIds([]);
      setDemoStories([]);
    } else {
      const articlesData = await storyService.getStoriesByIds({
        ids: _join(originalIds, ','),
      });

      setListIds(originalIds);
      setDemoStories(_get(articlesData, 'articles'));
    }

    setIdDisableDeleteButton(false);
  };
  const onAddBoxChange = (e) => {
    const value = _get(e, 'target.value', '');

    setArticleIdsText(value);
  };

  const onAddStoryIds = () => {
    const newestIds = _split(articleIdsText, ',').map((id) => _trim(id));
    if (articleIdsText !== '' && newestIds.length > 0) {
      setIsDisableAddButton(true);

      storyService.addDemoArticles([...listIds, ...newestIds]).then(() => {
        setIsDisableAddButton(false);
        onFetchingData();
        alert.info('Added demo articles successfully');
      });
    } else {
      alert.error('Invalid data');
    }
  };

  const onRemoveStory = (removeId) => {
    setIdDisableDeleteButton(true);
    if (removeId) {
      const newArray = _remove(listIds, (id) => id !== removeId);
      storyService.addDemoArticles(newArray).then(() => {
        onFetchingData();
        alert.info('Remove demo articles successfully');
      });
    } else {
      alert.error('Invalid Id');
    }
  };

  return (
    <Container>
      <Row className='mt-3'>
        <Col lg={'6'} xs={'12'}>
          <InputGroup className='mt-3'>
            <FormControl
              placeholder='Article ids, Ex: id1, id2, id3...'
              aria-label="Recipient's username"
              aria-describedby='basic-addon2'
              onChange={onAddBoxChange}
            />
            <InputGroup.Append>
              <Button
                className='btn-primary'
                disabled={isDisableAddButton}
                onClick={onAddStoryIds}
              >
                Add
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </Col>
      </Row>
      <Row className='mt-3'>
        {_map(demoStories, (story) => (
          <Col lg={'8'} xs={'12'} key={_get(story, 'id', '')}>
            <InputGroup className='mt-3'>
              <FormControl
                placeholder={`${_get(story, 'id', '')} - ${_get(
                  story,
                  'title'
                )}`}
                aria-label="Recipient's username"
                aria-describedby='basic-addon2'
                disabled
              />
              <InputGroup.Append>
                <Button
                  variant='danger'
                  onClick={() => onRemoveStory(_get(story, 'id'))}
                  disabled={isDisableDeleteButton}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default withAlert(DemoArticlesForm);
