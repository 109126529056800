import React from "react";

import Button from 'react-bootstrap/Button';

import { AuthConsumer } from "./AuthContext";

const Login = () => (
  <AuthConsumer>
    {({ login }) => (
      <Button variant="primary" onClick={login}>
        Login
      </Button>
    )}
  </AuthConsumer>
);

export default Login;
