import _ from 'lodash';

import { flatMapDeep, add, flow } from 'lodash/fp';

import { getData, putData, postData } from '../utils/fetch-api';

const baseUrl = `${process.env.REACT_APP_API_URL}`;

const increment = add(1);

const levelizeIterator = childrenProperty => (levelProperty, level) => function iterateLevel (level = 0) {
  return item => {
    item[levelProperty] = level;

    if (item.hasOwnProperty(childrenProperty)) {
      const step = flow(increment, iterateLevel, flatMapDeep);
      const nextLevel = step(level);
      const children = nextLevel(item[childrenProperty]);
      delete item[childrenProperty];
      return [item, children];
    }

    return _.pick(item, ['key', 'highlight', 'title', 'level']);
  };
};

const itemsLevelizer = levelizeIterator('children')('level')();

const levelizeTree = flatMapDeep(itemsLevelizer);

async function getFlatSectionNames() {

  const sections = levelizeTree(await getData(`${baseUrl}/philly`));

  return _.uniqBy(sections, 'title');
}

async function getActiveSections() {
  return _.sortBy(await getData(`${baseUrl}/active_sections`), 'highlight_order');
}

async function setActiveSection(section_name, section_key) {
  return postData(`${baseUrl}/active_sections`, { section_name, section_key })
}

async function setMultipleActiveSections(sections) {
  return putData(`${baseUrl}/active_sections`, sections)
}

function getSectionList() {
  return getData(`${baseUrl}/philly`);
}

const sectionService = {
  getActiveSections,
  getFlatSectionNames,
  setActiveSection,
  setMultipleActiveSections,
  getSectionList,
}

export default sectionService;
