import React, { Component } from 'react';

import _ from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { NavLink } from 'react-router-dom';

import Protected from '../auth/Protected';

import './CarouselContainer.css';
import { withContext } from './CurationContext';
import ItemActions from './ItemActions';
import PreviewArticle from '../common/PreviewArticle';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: 16,
  borderRadius: 8,

  // change background colour if dragging
  background: isDragging ? 'lightgrey' : '#fff',

  // styles we need to apply on draggables
  ...draggableStyle,
});

class CarouselContainer extends Component {

  constructor(props) {
    super(props);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidMount() {
    this._adjustGridColomns();
  }

  componentDidUpdate() {
    this._adjustGridColomns();
  }

  onDragEnd(result) {
    if (!result.destination) { // dropped outside the list
      return;
    }

    let {items, updateState } = this.props.context;
    items = reorder(items, result.source.index, result.destination.index);

    updateState({ items });
  }

  render() {
    const { loading } = this.props.context;
    const containerControl = {
      pointerEvents: loading ? 'none' : 'auto',
      opacity: loading ? '0.5': '1'
    };

    return (
      <div className="Carousel" style={containerControl}>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided, snapshot) => (
              <ul className="hs full"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                { this._renderItems() }
                <Protected privilege="curation:publish">
                  <li className="item new-item-btn">
                    <NavLink to="/curations/new">+</NavLink>
                  </li>
                </Protected>
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }

  _renderItems() {
    const { items = [] } = this.props.context;

    return _.map(items, (item, idx) => (

        <Draggable isDragDisabled={item.mode === 'auto'} key={idx} draggableId={idx} index={idx}>
            {(provided, snapshot) => (
              <li
                key={idx} className="item"
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style
                )}
              >
                <Protected privilege="curation:publish">
                   <ItemActions item={item} />
                </Protected>

                <PreviewArticle article={item} vertical />
              </li>
            )}
          </Draggable>
    ))

    }

  _adjustGridColomns() {
    const lists = document.querySelectorAll('.hs');
    lists[0].style.setProperty('--total', this.props.context.items.length + 1);

    setTimeout(() => {
      if (lists.length > 0) {
        const count = lists[0].querySelectorAll('.item').length;
        lists[0].style.setProperty('--total', count);
      }
    }, 100);
  }
}

export default withContext(CarouselContainer);
