import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';
import _map from 'lodash/map';

import NotificationApp, {NewNotification, EditNotification} from './components/notification';
import CurationApp, { NewCurationItem, EditCurationItem } from './components/curation';
import AnalyticsApp from './components/analytics';
import ActiveSection from './components/active_section';
import Settings from './components/settings';
import DemoArticles from './components/demo_articles';
import LiveActivities from './components/live-activities';

const NewsletterContainer = React.lazy(() => import('./components/newsletter'));
const NewsletterV2Container = React.lazy(() => import('./components/newsletter-v2'));
const HomePageContainer = React.lazy(() => import('./views/HomePage'));
const FusionSettingsContainer = React.lazy(() => import('./components/fusion-settings'));
const InkBlogContainer = React.lazy(() => import('./components/inkblog'));
const SwipeableContainer = React.lazy(() => import('./components/swipeable'));

export const tabs = [
  {
    path: "/home",
    component: () => (
      <Suspense fallback={<div>Loading....</div>}>
        <HomePageContainer />
      </Suspense>
    )
  },
  {
    path: "/notifications",
    component: NotificationApp,
    routes: [
      {
        path: "/notifications/new",
        component: NewNotification
      },
      {
        path: "/notifications/edit/:id",
        component: EditNotification
      },
    ]
  },
  ...(
    _map(['/original', '/alert', '/curated', '/ads'], (path) => (
      {
        path: `/inkbox${path}`,
        component: () => (
          <Suspense fallback={<div>Loading....</div>}>
            <NewsletterContainer />
          </Suspense>
        )
      }
    ))
  ),
  ...(
    _map(['/original', '/alert', '/curated', '/ads', '/marketing'], (path) => (
      {
        path: `/inkbox-v2${path}`,
        component: () => (
          <Suspense fallback={<div>Loading....</div>}>
            <NewsletterV2Container />
          </Suspense>
        )
      }
    ))
  ),
  {
    path: "/curations",
    component: CurationApp,
    routes: [
      {
        path: "/curations/new",
        component: NewCurationItem
      },
      {
        path: "/curations/edit",
        component: EditCurationItem
      },
    ]
  },
  {
    path: "/analytics",
    component: AnalyticsApp
  },
  {
    path: "/settings",
    component: Settings
  },
  {
    path: "/sections",
    component: ActiveSection
  },
  {
    path: "/demo-articles",
    component: DemoArticles
  },
  {
    path: '/fusion-settings',
    component: () => (
      <Suspense fallback={<div>Loading....</div>}>
        <FusionSettingsContainer />
      </Suspense>
    )
  },
  {
    path: "/live-activities",
    component: LiveActivities
  },
  {
    path: '/ink-blog',
    component: () => (
      <Suspense fallback={<div>Loading....</div>}>
        <InkBlogContainer />
      </Suspense>
    )
  },
  {
    path: '/swipeable',
    component: () => (
      <Suspense fallback={<div>Loading....</div>}>
        <SwipeableContainer />
      </Suspense>
    )
  },
];

// wrap <Route> and use this everywhere instead, then when
// sub routes are added to any route it'll work
export const RouteWithSubRoutes = route => (
  <Route
    path={route.path}
    render={props => (
      // pass the sub-routes down to keep nesting
      <route.component {...props} routes={route.routes} />
    )}
  />
);
