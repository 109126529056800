import _isString from 'lodash/isString';
import _get from 'lodash/get';

const processTopicTargeting = (targeting) => {
  const targetingType = _get(targeting, 'targetType.label', '');

  if (targetingType !== 'Topic') {
    return targeting;
  }

  const topics = targeting.selected;

  if (Array.isArray(topics) && topics.length > 0 && _isString(topics[0])) {
    return targeting;
  }

  return traverseTopicTree(topics, [], 3);
}

const traverseTopicTree = (topics, acc = [], level) => {
  if ((level - 1) > 0) {
    topics.forEach(topic => {
      const {
        label,
        selected,
        children
      } = topic;
      if (selected) acc.push(label);
      traverseTopicTree(children, acc, level - 1);
    });
    return acc;
  }
};

export {
  processTopicTargeting
};
