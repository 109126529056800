// @flow
import React, { memo, useEffect, useMemo, useState, useCallback } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton';
import JSONEditor from 'jsoneditor';
import styled from 'styled-components';
import {dropdownItems, staticGames} from '../constants';
import 'jsoneditor/dist/jsoneditor.css';
import { postData, getData } from '../../../utils/fetch-api';
import { getSectionFromPayloadName } from '../helpers'
import './index.css';

const baseUrl = `${process.env.REACT_APP_BASE_API_URL}gdc-live/`;
const GDC_TOKEN = process.env.REACT_APP_GDC_TOKEN;

const TextArea = styled.textarea`
  width: 300px;
  height: 30px;
  min-width: 500px;
`;

const StyledSpan = styled.div`
  padding: 0 20px;
  font-weight: bold;
`;

const StyledButton = styled.div`
  margin-left: 20px;
`;

const StyledText = styled.div`
  font-size: 20px;
  margin-top: 20px;
`;

const StyledColumn = styled.div`
  display: flex;
  align-items: center;
`;

const StyledToken = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 20px;
`;

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  border-bottom: 1px solid #ccc;
  justify-content: space-between;
`;

const JSONupdater: React.FC = ({jsonRef}) => {
  const [jsoneditor, setJsoneditor] = useState(null);
  const [data, setData] = useState(staticGames.mlb);
  const [token, setToken] = useState('');
  const [loadedTokens, setLoadedTokens] = useState(false);
  const [tokenList, setTokenList] = useState([]);
  const [dropdownValue, setDropdownValue] = useState('mlb');
  // determine the section the api needs based off the prefix of the filename. ex: mlb => phillies
  const section = useMemo(() => getSectionFromPayloadName(dropdownValue), [dropdownValue]);
  const isLoading = false;

  const onChangeJSON = (json) => {
    setData(json);
  };

  const onEditable = ({field}) => {
    if (field === 'eventId') {
      return false;
    } else {
      return true;
    }
  }

  const getEventWithOverride = useCallback((eventId, league) => {
      getData(`${baseUrl}override/${eventId}`).then((res) => {
        if (res?.response?.overrideData) {
          jsoneditor.update(res.response.overrideData);
          setData(res.response.overrideData)      
        } else {
          const gameData = staticGames[league || 'mlb'];
          jsoneditor.update(gameData)
        }
      })
  }, [jsoneditor]);

  const updateTokenList = (eventId) => {
    if (eventId) {
      getData(`${baseUrl}tokens/${eventId}`).then((res) => {
        if (res) {
          setTokenList(res?.response || [])
        } else {
          console.log('error')
        }
      })
    }
  };

  useEffect(() => {
    const options = {
      mode: 'tree',
      onChangeJSON,
      onEditable,
    }
    if (!jsoneditor && data && jsonRef) {
      setJsoneditor(new JSONEditor(jsonRef.current, options));
      
    }
    if (jsoneditor && data && jsonRef) {
      if (loadedTokens === false) {
        getEventWithOverride(data.eventId);
        jsoneditor.set(staticGames.mlb)
        jsoneditor.expandAll()  
        updateTokenList(data.eventId);
        setLoadedTokens(true);
      }
    }
  }, [jsonRef, jsoneditor, data, loadedTokens, getEventWithOverride]);


  const updateJSON = (value) => {
    const gameData = staticGames[value];
    setDropdownValue(value)
    getEventWithOverride(gameData.eventId, value)
    updateTokenList(gameData.eventId);
  }
  
  const handleRun = (e) => {
    const payload = jsoneditor.get();
    const eventId = payload?.eventId;
    
    if (!eventId) {
      alert('Payload does not contain an eventId');
      return;
    }

    postData(`${baseUrl}register/${eventId}`, {
      token,
      section,
    }, false, GDC_TOKEN).then((res) => {
      if (res && res.message) {
        alert(res.message);
      }
      postData(`${baseUrl}override/${eventId}`, {
        payload,
        section,
        clear: false
      }).then((res) => {
        setData(payload);
        if (res && res.message) {
          alert(res.message);
        }
      }).catch((err) => {

      });
    }).catch((err) => {
     // alert('Unable to register the token.');
      console.log(err);
    });
  }

  const handleClear = (e) => {
    const gameData = staticGames[dropdownValue];
    setData(gameData)
    postData(`${baseUrl}override/${data?.eventId}`, { 
      section,
      clear: true
    });
  }
  
  const onTokenChange = (token) => {
    setToken(token);
  };

  const JSONDropdownMenu = () => {
    return (
      <DropdownButton id="dropdown-1" title="Select Premade JSON">
      {
        dropdownItems.map((value, index) => (
          <Dropdown.Item key={`dropdown-1-type-${index}`} onClick={() => updateJSON(value)}>{value}</Dropdown.Item>
        ))
      }
    </DropdownButton>
    )
  }

  const TokenDropdownMenu = () => {
    return (
      <DropdownButton id="dropdown-2" title="Select Token" disabled={tokenList.length === 0}>
      {
        tokenList.map((tokenObj, index) => (
          <Dropdown.Item key={`dropdown-2-type-${index}`} onClick={() => onTokenChange(tokenObj.token)}>{tokenObj.token}</Dropdown.Item>
        ))
      }
    </DropdownButton>
    )
  }

  return (
    <>
    
      <StyledColumn>
        <JSONDropdownMenu />
      </StyledColumn>

      <StyledWrapper>
    <StyledToken>
      <TokenDropdownMenu />
      <StyledSpan>OR</StyledSpan>
      <TextArea placeholder="Enter Apple Push Token" onChange={(e) => onTokenChange(e.currentTarget.value)} value={token}></TextArea>
    </StyledToken>
    <StyledColumn>
        <StyledButton>
        <Button
          onClick={handleRun}
          disabled={!token || isLoading}
          variant="primary"
          size="sm">Run
        </Button>
        </StyledButton>
        <StyledButton>
        <Button
          onClick={handleClear}
          disabled={false}
          variant="primary"
          size="sm">Clear
        </Button>
        </StyledButton>
      </StyledColumn>
    </StyledWrapper>
    <StyledText>Selected JSON: <b>{dropdownValue}</b></StyledText>
    </>
  )
};

export default memo(JSONupdater);