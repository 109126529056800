import _ from 'lodash';

import React, { Component } from 'react';

import { withAlert } from 'react-alert'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import {sectionService} from '../../services';
import { withContext } from './SectionContext';

import messages from './messages.json';

class ActionBar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  onPublish = async () => {
    const { sections } = this.props.context;

    this.setState({ isLoading: true });

    await this.handleSave();

    const newSections = sections.map(({hasEdit, refreshKey, ...others}) => ({
      hasEdit: false, 
      refreshKey: hasEdit ? refreshKey : !refreshKey, 
      ...others
    }));

    this.props.context.updateState({
      sections: newSections,
      oldValues: newSections.slice()
    });

    this.setState({ isLoading: false });
  };

  onCancel = async () => {
    let oldValues = this.props.context.oldValues;

    await this.props.context.updateState({
      oldValues: oldValues.map(({hasEdit, title, editedValue, ...others}) => ({
        editedValue: title,
        hasEdit: false, 
        title,
        ...others
      }))
    });

    oldValues = this.props.context.oldValues;

    await this.props.context.updateState({
      sections: oldValues, 
      oldValues: oldValues.slice()
    });
  };

  handleSave = async () => {
    const { sections } = this.props.context;

    const sectionsPayload = sections.filter(s => s.title != null)
      .map(({ key, title }, index) => ({ 
        section_key: key, 
        section_name: title, 
        highlight_order: index + 1 
      }));

    const results = await sectionService.setMultipleActiveSections(sectionsPayload);

    if (this.props.alert) {

      if (results == null) {
        return this.props.alert.error(messages.ink.activeSections.alerts.error);
      }
  
      this.props.alert.info(messages.ink.activeSections.alerts.info);
    }
  };

  hasNoEdits = () => {
    const { sections, oldValues } = this.props.context;

    if (sections.length !== oldValues.length) {
      return false;
    }

    if (_(sections).some(({hasEdit}) => hasEdit === true)) {
      return false;
    }

    return true;
  };

  hasErrors = () => {
    const { sections, oldValues } = this.props.context;

    // When there are deletions but has empty section(s), prevent from publishing
    if (sections.length !== oldValues.length && _(sections).some(({key}) => key === true)) {
      return true;
    }

    // Prevent publishing any empty section element in the payload
    if (_(sections).some(({key}) => key === true)) {
      return true;
    }

    // Prevent publishing when there are duplicate valid sections
    if (_.difference(sections, _.uniqBy(sections, 'title')).length > 0) {
      return true;
    }

    // When the page appears there are red indicated section search form, prevent publishing
    return false;
  };

  render() {
    return (
      <Row className="action-bar">
        <Col></Col>
        <Col className="pull-right">
          <Button
            variant="secondary"
            onClick={this.onCancel}
            size="sm">Cancel
          </Button>
        </Col>
        <Col md={2} className="pull-right">
          <Button
            onClick={this.onPublish}
            disabled={this.state.isLoading || this.hasErrors() || this.hasNoEdits()}
            variant="primary"
            size="sm">Publish
          </Button>
        </Col>
      </Row>
    );
  }

}

export default withContext(withAlert(ActionBar));