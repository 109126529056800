// @flow
import React from 'react'
import styled, { keyframes } from 'styled-components';

const loadingAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Loader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  border: 2px solid #ccc;
  border-top-color: #333;
  border-radius: 50%;
  animation: ${loadingAnimation} 0.6s linear infinite;
`;

const Loading: React.FC = () => (
  <div>
    <Loader />
  </div>
);


export default React.memo(Loading);
