import React, { Component } from 'react';
import _ from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';

import './CarouselContainer.css';
import { withContext } from './CurationContext';
import { confirmDialog } from '../common/ConfirmDialog';
import messages from './messages.json';

class ItemActions extends Component {

  removeItem = () => {
    const { item } = this.props;
    const message = messages.ink.curation.confirmation.remove;

    confirmDialog({message}).then(resolved => {
      const { items, updateState } = this.props.context;
      const filtered = _.filter(items, i => i.story_id !== item.story_id);
      updateState({items: filtered});
    }, rejected => {
      // ignore
    });
  }

  toggleAnchor = item => {
    return () => {};
    /* TODO: we have to figure out a way to use is_anchored
    return () => {
      let { items, updateState } = this.props.context;
      const idx = _.findIndex(items, i => i.id === item.id);
      let e = items[idx];
      e.is_anchored = !e.is_anchored;
      items.splice(idx, 1, e);
      updateState({items});
    }
    */
  };

  render() {
    const { item } = this.props;
    const mode = item.mode || 'auto';

    const el = mode === 'manual' ?
      <div className="align-center">
        <span className="action" onClick={this.removeItem}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </span>
      </div> :
      <div className="align-center">
        <span className="action" onClick={this.toggleAnchor(item)}>
          <FontAwesomeIcon icon={item.is_anchored ? faLock : faUnlock} />
        </span>
      </div>

    return el;
  }

}

export { ItemActions }; // for easier testing
export default withContext(ItemActions);
