import React from 'react';

import Alert from 'react-bootstrap/Alert';

class Unauthorized extends React.Component {
  render() {
    const message = this.props.message || "You are now registered in Ink, but you need the Help Desk to give you permissions to use the app. Contact support or the help desk to request those permissions.";

    return (
      <Alert variant={'warning'} style={{marginTop: 20, color: '#e02229'}}>
        {message}
      </Alert>
    );
  }
}

export default Unauthorized;
