const StoryMetadata = {
  Slug: 'meta_slug',
  Credits: 'meta_credits',
  Team1: 'meta_team1',
  Team2: 'meta_team2',
  SeoKeywords: 'meta_seo_keywords',
  Tags: 'meta_tags',
  HeadlinesWeb: 'meta_headlines_web',
  HeadlinesMetaTitle: 'meta_headlines_meta_title',
}

export default Object.freeze(StoryMetadata);
