import _ from 'lodash';

import { getData, putData } from '../utils/fetch-api';
const baseUrl = `${process.env.REACT_APP_API_URL}`;

class PermissionService {

  constructor() {
    this.roles = null;
    this.rules = null;
  }

  async getRules() {
    if (!this.rules) {
      this.rules = await getData(`${baseUrl}/auth/rules`);
    }
    // temporary set rules for producer
    _.set(this.rules, 'producer.temp', ['inkbox:modify']);
    _.set(this.rules, 'superuser.temp', ['inkbox:modify']);
    _.set(this.rules, 'manager.temp', ['inkbox:modify']);
    return this.rules;
  }

  async authorized(role, action, data) {
    if (!role || !role.name) return false;

    const rules = await this.getRules();
    const permissions = rules[role.name];

    if (!permissions) {
      return false;
    }

    // static rules
    const staticPermissions = permissions.static;
    if (staticPermissions && staticPermissions.includes(action)) {
      return true;
    }

    // temp rules
    const tempPermissions = _.get(permissions, 'temp', []);
    if (tempPermissions && tempPermissions.includes(action)) {
      return true;
    }

    // dynamic rules
    const dynamicPermissions = permissions.dynamic;
    if (dynamicPermissions) {
      const permissionCondition = dynamicPermissions[action];
      if (!permissionCondition) {
        return false;
      }

      return permissionCondition(data);
    }

    return false;
  }

  getRoleUsers() {
    return getData(`${baseUrl}/roleusers?timestamp=${(new Date()).getTime()}`);
  }

  async getAvailableRoles() {
    if (this.roles) return this.roles;

    this.roles = await getData(`${baseUrl}/roles`);
    return this.roles;
  }

  async updateUserRole(id, roleName) {
    const roles = await this.getAvailableRoles();
    const role_id = _.chain(roles).find(r => r.name === roleName).get('id').value();

    return putData(`${baseUrl}/roleusers/${id}`, {role_id});
  }
}

export {PermissionService};
export default new PermissionService();
