import React, { Component } from 'react';

import 'react-bootstrap-typeahead/css/Typeahead.css';

import {AsyncTypeahead, Highlighter} from 'react-bootstrap-typeahead';
import _ from 'lodash';

import './SearchArticle.css';
import PreviewArticle from './PreviewArticle';

import {storyService} from '../../services';
import { story2CurationItem } from '../../utils/common';

const ArticleMenuItem = ({ article, keyword } = {}) => {
  return [
    <Highlighter key="id" search={keyword}>
      {article.story_title}
    </Highlighter>,
    <div key="headline">
      <small>
        {article.desc}
      </small>
    </div>,
  ];
}

class SearchArticle extends Component {
  state = {
    allowNew: false,
    isLoading: false,
    multiple: false,
    options: [],
    selected: null,
  };

  static getDerivedStateFromProps(props, state) {
    if(props.selected) {
      return {selected: props.selected};
    }
    return null;
  }

  removePreview = () => {
    this.setState({selected: null});
    this._updateSelected(null);
  };

  handleChange = selected => {
    this.setState({selected: selected[0]}); // the typeahead selected always an array
    this._updateSelected(selected[0]);
  };

  componentDidUpdate () {
    if (!this._typeahead) return;

    if(this.props.keepFocus) {
      this._typeahead.getInstance().focus();
    }
  }

  render() {
    return this.state.selected ? this._renderPreview() : this._renderSearch();
  }

  _renderPreview() {
    return (
      <div className="SearchArticle-preview">
        <div className="remove-btn align-self-center mr-3" onClick={this.removePreview}>x</div>
        <PreviewArticle article={this.state.selected} />
      </div>
    );
  }

  _updateSelected(selected) {
    const {updateSelected} = this.props;
    if(updateSelected) updateSelected(selected);
  }

  _renderSearch() {
    return (
      <AsyncTypeahead
        {...this.state}
        labelKey="story_title"
        minLength={3}
        delay={200}
        isInvalid={!this.props.isValid}
        ref={(ref) => this._typeahead = ref}
        onSearch={this._handleSearch}
        placeholder="Search for a published article..."
        onChange={this.handleChange}
        filterBy={this._filter}
        renderMenuItemChildren={(option, props) => (
          <ArticleMenuItem key={option.id} article={option} keyword={props.text}/>
        )}
      />
    );
  }

  _filter = (option, props) => true; // bypass filter so we can display whatever comes back from server

  _handleSearch = (query) => {
    this.setState({isLoading: true});

    storyService.searchStoriesByGraphQL(query)
      .then(result => {
        const stories = _.get(result, 'data.searchPublishedStories', []);
        let options = this._buildOptions(stories);

        this.setState({
          isLoading: false,
          options
        });
      })
      .catch(err => {
        this.setState({
          isLoading: false,
          options: [],
        });
      });
  }

  _buildOptions(stories) {
    return _.map(stories, story => story2CurationItem(story));
  }
}

export default SearchArticle;
