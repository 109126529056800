import React, { Component } from 'react';

class EditCurationItem extends Component {
  render() {
    return (
      <div>Edit Curation Here</div>
    );
  }
}

export default EditCurationItem;
