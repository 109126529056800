import _ from 'lodash';

import React, { Component } from 'react';

import { withContext } from './SectionContext';
import { withInternalAuthContext } from '../auth/AuthContext';

import { AsyncTypeahead, Highlighter, Menu, MenuItem } from 'react-bootstrap-typeahead';

const SectionMenuItem = ({ position, option, keyword, disabled } = {}, ) => {
  const level = option.level;

  const style = {
    paddingLeft: `${level * 2}em`,
  }

  return <MenuItem disabled={disabled} option={option} position={position}>
    <div style={style}>
      {disabled ? '✔ ' : ''}<Highlighter search={keyword}>{option.sub_section || option.title}</Highlighter>
    </div>
  </MenuItem>;
};

class SectionSearch extends Component {

  constructor(props) {
    super(props);

    this.state = {
      textValue: '',
      maxHeight: '150px',
      minLength: 0,
      allowNew: false,
      isLoading: false,
      multiple: false,
      invalid: this.isInvalidSection(this.props.editedValue)
    };

    this.props.authContext.protect({
      ref: this,
      privilege: 'active_section:publish',
      methods: [
        'handleChange',
        'filterSearch',
        '_onInputChange',
        '_renderMenu',
        'isInvalidSection',
      ]
    });
  }

  handleChange = newlySelected => {

    const { sections } = this.props.context;

    const newSection = newlySelected[0] || { key: null, title: null };

    newSection.editedValue = newSection.title || '';
    newSection.hasEdit = true

    sections[this.props.index] = newSection;

    this.props.context.updateState({ sections });

    this.setState({ sectionSelected: true });
  };

  filterSearch = (option, props) => {
    const text = props.text.toUpperCase();

    if (option.sub_section) {
      return option.title.toUpperCase().startsWith(text) || option.sub_section.toUpperCase().startsWith(text);
    }

    if (option.title.toUpperCase().startsWith(text)) {
      return true;
    }

    if (_.some(option.sub_sections, ({ title }) => (title || '').toUpperCase().startsWith(text))) {
      return true;
    }
  };

  componentDidMount() {
    this._typeahead.getInstance().setState({ text: this.props.editedValue || ''});
    if (this.props.isNew) this._typeahead.getInstance().focus();
  }

  _onInputChange = text => {
    const { index } = this.props;
    const { sections } = this.props.context;

    if (this.isInvalidSection(text)) {
      sections[index] = { key: true, title: null };
      this.setState({invalid: true});
    } else {
      sections[index].key = _.result(_.find(this.props.options, {title: text}), 'key');
      sections[index].title = text;
    }

    sections[index].editedValue = text;
    sections[index].hasEdit = sections[index].editedValue === sections[index].title;

    this.props.context.updateState({sections});
  };

  _renderMenu = (results, props) => {

    const { sections } = this.props.context;

    let position = 0;

    const items = results.map((option, index) => {
      const disabled = _.some(sections, ({key, editedValue}) => key === option.key && editedValue === option.title);

      const menuItemProps = {
        option,
        disabled,
        keyword: props.text
      };

      if (!disabled) {
        menuItemProps.position = position;
        position++;
      }

      return <SectionMenuItem key={`menu-item-${this.props.index}-${index}`} {...menuItemProps}/>;
    });

    return <Menu {...props}>{items}</Menu>;
  };

  isInvalidSection = sectionName => {
    const { options } = this.props;
    const { sections } = this.props.context;

    if (_.filter(sections, ({editedValue}) => editedValue === sectionName).length > 1) {
      return true;
    }

    if (!_.some(options, ({title, sub_section}) => (sub_section || title) === sectionName)) {
      return true;
    }

    return false;
  };

  render() {
    const { authorized } = this.state;

    return (
      <div>
        <AsyncTypeahead
          {...this.state}
          disabled={!authorized}
          selectHintOnEnter={true}
          bodyContainer={true}
          searchText={`There is no section named "${this.state.textValue}"`}
          labelKey="title"
          options={this.props.options}
          isValid={this.props.hasEdit}
          isInvalid={this.state.invalid}
          ref={(ref) => this._typeahead = ref}
          onSearch={query => true}
          onChange={this.handleChange}
          onInputChange={this._onInputChange}
          filterBy={this.filterSearch}
          renderMenu={this._renderMenu}
        />
      </div>
    );
  }
}

export default 
  withContext(
    withInternalAuthContext(
      SectionSearch
  ));
