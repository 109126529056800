import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

class ProgressDialog extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showDialog: true,
      status: 'processing',
      result: null,
    }
  }

  componentDidMount() {
    this.props.promise.then(result => {

      // check the different responses when send notfications to multiple devices
      if(result.responses && result.failureCount !== 0) {
        this.setState({
          status: 'error',
          result,
        });
      } else {
        this.setState({
          status: 'success',
          result,
        });
      }
    }, reason => {
      this.setState({
        status: 'error',
        result: reason.error,
      });
    }).finally(
      () => {
        if( this.props.processResovled) {
          this.props.processResovled(this.state.result);
        }
      }
    );
  }

  handleAction = () => {
    this.setState({
      showDialog: false
    });
    this.props.resolve(this.state);
  }

  render() {
    const { title, message } = this.props;

    return (
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop={'static'}
        show={this.state.showDialog}
        onHide={this.handleCancel}
      >
        <Modal.Header>
          <Modal.Title>{title || 'Processing...'}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{message || ''}</p>
          <p>{this.state.status}</p>
        </Modal.Body>

        <Modal.Footer>
          {this._renderActions()}
        </Modal.Footer>
      </Modal>
    );
  }

  _renderActions() {
    const {status} = this.state;

    let el;
    switch(status) {
      case 'success':
        el = <Button variant="success" onClick={this.handleAction}>{this.props.successButtonLabel || 'Done'}</Button>;
        break;
      case 'error':
        el = <Button variant="danger" onClick={this.handleAction}>{this.props.errorButtonLabel || 'Error'}</Button>;
        break;
      default: // make eslinting happy
        el = <Fragment />;
    }

    return el;
  }
}

export const progressDialog = props => {
  const mountDiv = document.body.appendChild(document.createElement('div'));
  const cleanup = () => ReactDOM.unmountComponentAtNode(mountDiv);

  const promise = new Promise((resolved, rejected) => {
    ReactDOM.render(
      <ProgressDialog
        reject={rejected}
        resolve={resolved}
        {...props}
      />,
      mountDiv
    );
  });

  return promise
    .then( result => result, reason => Promise.reject(reason) )
    .finally( () => cleanup() );
}

export default ProgressDialog;
