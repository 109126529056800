import React, { Component, Fragment } from 'react';

import _ from 'lodash';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';
import { Typeahead } from 'react-bootstrap-typeahead';

import InputGroup from 'react-bootstrap/InputGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Dropdown from 'react-bootstrap/Dropdown';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton';
import TopicPicker from './TopicPicker';
import AddTokensBox from './AddTokensBox';

import { CONSTANTS } from '../../utils/common';
import { withContext } from './NotificationContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes} from '@fortawesome/free-solid-svg-icons';
import '../../styles/Notification.css';

const TARGET_TYPES = {
  all: {
    label: 'All Customers',
  },
  app: {
    label: 'App',
    placeholder: 'Please select apps',
  },
  topic: {
    label: 'Topic',
    placeholder: 'Pick topics',
  },
  device: {
    label: 'Device',
    placeholder: 'Device tokens',
  }
};

const MAX_LEVEL = CONSTANTS.topic_hierarchy_max_level;

class Targeting extends Component {

  state = {
    showTopicPicker: false,
    showAddTokensBox: false
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    
    const targeting = _.get(nextProps.context, 'currentMessage.targeting');
    const type = _.get(targeting, 'targetType.label');

    if('App' === type) {
      return {selectedApps: _.get(targeting, 'selected', null)};
    } else if('Device' === type) {
      return {selectedDevice: _.get(targeting, 'selected', '')};
    } else {
      return null;
    }
  }

  handleAllChanged = () => {
    const {currentMessage, updateState} = this.props.context;
    currentMessage.targeting = {
      targetType: TARGET_TYPES['all'],
      isValid: true,
    };

    updateState({currentMessage});
  }

  handleAppChanged = selected => {
    if(!selected) {
      selected = this.state.selectedApps || ['iOS', 'Android', 'Web'];
    }

    const {currentMessage, updateState} = this.props.context;
    currentMessage.targeting = {
      targetType: TARGET_TYPES['app'],
      selected,
      options: ['iOS', 'Android', 'Web'],
      isValid: selected.length > 0,
    };

    updateState({currentMessage});
  }

  handleTopicChanged = () => {
    const { rootTopic } = this.props.context;
    const {currentMessage, updateState} = this.props.context;

    currentMessage.targeting = {
      targetType: TARGET_TYPES['topic'],
      selected: rootTopic.prune().children,
      isValid: this._isTopicValid(rootTopic.children),
    };
    updateState({currentMessage});
    this.setState({
      showTopicPicker: false,
    })
  }

  clearChildSelected = (topic) => {
    _.forEach(topic.children, child => {
      _.set(child, 'selected', false);
      
      if (_.isEmpty(child.children === false)) {
        this.clearChildSelected(child);
      }
    });
  };

  findAndToggleOffTopicInList = (topicKey, selectedTopics) => {
    _.forEach(selectedTopics, topic => {
      if (topic.key === topicKey) {
        _.set(topic, 'selected', false);

        if (_.isEmpty(topic.parent) === false) {
          _.set(topic, 'parent.selected', false);
        }

        if (_.isEmpty(topic.children) === false) {
          this.clearChildSelected(topic);
        }
      }

      if (_.isEmpty(topic.children) === false) {
        this.findAndToggleOffTopicInList(topicKey, topic.children);
      }
    })
  }
  
  handleOnToggleOffTopic = (topicKey) => {
    const rootTopic = _.cloneDeep(this.props.context.rootTopic);
    const currentSelectedItem = _.get(rootTopic, 'children', []);

    if (_.isEmpty(currentSelectedItem) === false) {
      this.findAndToggleOffTopicInList(topicKey, currentSelectedItem);
      const { currentMessage, updateState } = this.props.context;

      currentMessage.targeting = {
        ...currentMessage.targeting,
        selected: rootTopic.prune().children,
        isValid: this._isTopicValid(rootTopic.children),
      };

      updateState({rootTopic, currentMessage});
    }
  }

  handleDeviceChanged = async () => {
    const {currentMessage, setStateAsync, tokens} = this.props.context;

    const compacted = _.compact(tokens);
    currentMessage.targeting = {
      targetType: TARGET_TYPES['device'],
      selected: tokens,
      // this is note from firebase: A non-empty array containing up to 100 device registration tokens.
      isValid: compacted.length > 0 && compacted.length <= CONSTANTS.max_token_num,
    };

    await setStateAsync({currentMessage});

    this.setState({
      showAddTokensBox: false,
    });
  };


  onHide = () => {
    this.setState({
      showTopicPicker: false,
    });
  }

  onHideAddTokensBox = () => {
    this.setState({
      showAddTokensBox: false,
    });
  }

  showTopicPicker = () => {
    this.setState({
      showTopicPicker: true,
    });
  }

  showAddTokensBox = () => {
    this.setState({
      showAddTokensBox: true,
    });
  }

  onDeleteToken = async (index, currentTokens) => {
   const {tokens} =  this.props.context;
   // in case we load compose notification the tokens get from context always = [], so we much assign variable 
   if( currentTokens.length !== tokens.length) {
    await this.props.context.setStateAsync({tokens: currentTokens});
    this._deleteToken(index);
   } else {
    this._deleteToken(index);
   }
  }
  _deleteToken(index) {
    const {deleteToken} =  this.props.context;
    deleteToken(index).then( () => {this.handleDeviceChanged()} );
  }
  render() {
    const {currentMessage} = this.props.context;

    const title = _.get(currentMessage, 'targeting.targetType.label', 'Targeting');

    const style = {width: '70%'}
    return (
      <InputGroup>
        <DropdownButton
          as={InputGroup.Prepend}
          variant="outline-secondary"
          title={title}
          id="input-group-dropdown-targeting"
        >
          <Dropdown.Item onClick={() => this.handleAllChanged()}>All Customers</Dropdown.Item>
          <Dropdown.Item onClick={() => this.handleAppChanged()}>App</Dropdown.Item>
          <Dropdown.Item onClick={this.handleTopicChanged}>Topic</Dropdown.Item>
          <Dropdown.Item onClick={() => this.handleDeviceChanged()}>Device</Dropdown.Item>
        </DropdownButton>
        <InputGroup.Append style={style}>
          {this._renderTargetingPicker()}
        </InputGroup.Append>
        {this._getTopicPickerAndAddTokenBox()}
      </InputGroup>
    );
  }

  _getTopicPickerAndAddTokenBox() {
    if(this.state.showTopicPicker) {
      return <TopicPicker
        show={this.state.showTopicPicker}
        onHide={this.onHide}
        onDone={this.handleTopicChanged}
      />;
    }

    if(this.state.showAddTokensBox) {
      return <AddTokensBox
        show={this.state.showAddTokensBox}
        onHide={this.onHideAddTokensBox}
        onDone={this.handleDeviceChanged}
      />;
    }

    return <Fragment />;
  }



  _renderTargetingPicker() {
    let {targetType, isValid, options, selected } = this.props.context.currentMessage.targeting;
    let el;

    switch(targetType.label) {
      case 'All Customers':
        el = <InputGroup.Text>Send to all customers</InputGroup.Text>
      break;

      case 'App':
        el = <Typeahead
          id="targeting"
          clearButton
          selected={selected}
          multiple
          options={options}
          isInvalid={!isValid}
          placeholder={targetType.placeholder}
          renderMenu={this._renderMenu}
          onChange={this.handleAppChanged}
          onInputChange={this.handleInputChange}
        />;
        break;

      case 'Topic':
        el = <InputGroup.Text>
          <Container onClick={this.showTopicPicker} >
            <Row>
              {this._renderTreeNodes()}
            </Row>
          </Container>
        </InputGroup.Text>;
        break;

      case 'Device':
        el = this._renderDeviceTokensBox(_.compact(selected));
        break;

      default:
        el = <InputGroup.Text>Please select a target first</InputGroup.Text>;
    }

    return el;
  }

  _renderDeviceTokensBox (tokens) {
    return (
      <div className="device-tokens-box">
      <div className="tokens-box">
      {this._renderChosenTokens(tokens)}
      </div>
    <Button variant="outline-secondary" onClick={this.showAddTokensBox} className="add-more-token-button">
    <FontAwesomeIcon icon={faPlus} style={{height: '10px', display:'block'}}/>
    </Button>
    </div>
    );
  }
  _renderChosenTokens(tokens) {
    return tokens.map(
      (token, idx) => {
        return (          
          <div className="md-chip" key={idx}>
          <span>{token}</span>
          <span className="fill-remaining-space"></span>
          <Button variant="link" className="md-chip-remove" onClick={ () => this.onDeleteToken(idx, tokens)}>
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
        )
      }
    )
  
  }
  _renderTreeNodes() {
    const { rootTopic } = this.props.context;
    if(!rootTopic || !this._isTopicValid(rootTopic.children)) return <span>Click to choose topics</span>;

    return _.map(rootTopic.children, (node, idx) => this._renderNode(node, 1, idx));
  }

  _renderNode(node, level, idx) {
    let ret = [];
    const childSelected = _.some(node.children, 'selected');
    if(node.selected || childSelected) {
      const item = this._getBadge(node.key, node.selected, node.label);
      ret.push(item);
    }

    if(node.children.length > 0 && childSelected && level < MAX_LEVEL) {
      ret.push(<span key={'spano' + idx}>[</span>)
      _.forEach(node.children, (child, i) => {
        ret.push(this._renderNode(child, level + 1, idx * 100 + i));
      });
      ret.push(<span key={'spanc' + idx}>]</span>)
    }
    ret = _.compact(ret);

    return ret.length > 0 ? ret : null;
  }

  _getBadge(key, selected, label) {
    return <h6 key={'h' + key} className="chip-area">
      <Badge key={key} variant={selected ? 'primary' : 'secondary'} pill>
        <span>{label}</span>
        <span
          className="close-button"
          onClick={(event) => {
            event.stopPropagation();
            this.handleOnToggleOffTopic(key);
          }}
        >(x)
        </span>
      </Badge>
    </h6>;
  }

  _isTopicValid(topics) {
    let ret = _.some(topics, 'selected');

    if(!ret) {
      _.forEach(topics, topic => {
        ret = this._isTopicValid(topic.children);
        if(ret) return false;
      });
    }

    return ret;
  }

  // _processTokens(tokens) {
  //   return _.chain(tokens || '').split(/\n/).map(t => _.trim(t)).value();
  // }
}

export default withContext(Targeting);
