import React from 'react';
import { Redirect } from "react-router-dom";
import _ from 'lodash';
import TreeNode from './tree-node';

const CONSTANTS = {
  topic_hierarchy_max_level: 2,
  max_token_num: 100,
};

function story2CurationItem(story) {
  return {
    story_id: _.get(story, 'id'),
    story_title: _.get(story, 'title'),
    story_url: _.get(story, 'item_url'),
    desc: _.get(story, 'synopsis'),
    image_url: _.get(story, 'image_url'),
    author: _.get(story, 'author'),
    subtype: _.get(story, 'subtype'),
    cropInfo: _.get(story, 'cropInfo'),
    article_tags: _.get(story, 'article_tags'),
    creditsBasic: _.get(story, 'creditsBasic', ''),
    primary_section_id: _.get(story, 'taxonomy.primary_section._id', ''),
  };
}

function redirectTo(pathname, state) {
  return <Redirect to={{
    pathname,
    state
  }} />;
}

function createTopicsTree({topics, topicsRelationship}) {
  const topicsRelationshipClone = _.map(topicsRelationship, _.clone);
  topics = [...topics.values()]
  const topicGroupByParentId = _.groupBy(topicsRelationshipClone, 'parent_id');

  const processTree = (dataChildren, parentKey) => {
    return dataChildren.map(_itemChildren => {
      const {child_id} = _itemChildren;
      const title = _.result(_.find(topics, {id: child_id}), 'name', ''); //will always refer to the first element
      const key = `${parentKey}/${title}`;
      const children = _.get(topicGroupByParentId, child_id, []);
      return {
        title,
        key,
        id: child_id,
        children: processTree(children, key),
      };
    });
  };

  return processTree(_.get(topicGroupByParentId, '[1]', []), ''); // 1 is Root, '' is Root key
};

function _buildRootTopic(sections) {
  const rootTopic = new TreeNode('root', '_root_');
  sections.forEach(section => rootTopic.add(_buildTree(section)));
  return rootTopic;
}

function _buildTree(section) {
  const node = new TreeNode(section.title, section.key, {}, section.id);
  section.children.forEach(child => node.add(_buildTree(child)));
  return node;
}

export {
  CONSTANTS,
  story2CurationItem,
  redirectTo,
  createTopicsTree,
  _buildRootTopic,
  _buildTree,
};
