import _ from 'lodash';

class TreeNode {

  constructor(label = 'treenode', key, data = {}, id = 1) {
    this.id = id;
    this.label = label;
    this.key = key || _.random(2147483647);
    this.data = data;

    this.children = [];
    this.parent = null;
    this.selected = false;
  }

  add(child) {
    this.children.push(child);
    child.parent = this;
  }

  /*
  remove(child) {
    console.log(' =>  => Not implemented');
  }

  traverse(type = 'bfs') { // another option is dfs
    console.log(' =>  => Not implemented');
  }
  */

  contains(node) {
    const {id} = node;
    const parent_id = _.get(node, 'parent.id', 1);
    const containsChild = (children) => children.filter(_child => _child.id === id).length > 0;
    let result = false;
    if (parent_id === 1) {
      result = containsChild(this.children);
    } else {
      const findNode = (children) => {
        children.forEach(_child => {
          if (_child.id === parent_id) {
            result = containsChild(_child.children);
          } else {
            findNode(_child.children);
          }
        });
      }
      findNode(this.children);
    }
    return result;
  }

  select() {
    this.selected = true;

    // handle children
    this.children.forEach(child => child.select());

    // update parent selected state
    if (!this.parent) return;
    this.parent.selected = _.every(this.parent.children, child => child.selected);
  }

  deselect() {
    this.selected = false;

    this.children.forEach(child => child.deselect());

    if(!this.parent) return;
    this.parent.selected = false;
  }

  prune() {
    let children = [];
    this.children.forEach(child => {
      let tmp = child.prune();
      children.push(tmp);
    });

    return {
      key: this.key,
      label: this.label,
      selected: this.selected,
      children,
    };
  }

}

export default TreeNode;
