import React, { Component } from 'react';

import '../curation/CarouselContainer.css';
import '../../styles/Curation.css';

import Can from '../auth/Can';
import Unauthorized from '../auth/Unauthorized';
import { AuthConsumer } from '../auth/AuthContext';

import Protected from '../auth/Protected';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import SectionSearch from './MultipleSectionList';

import ContextProvider from './SectionContext';
import ActionBar from './ActionBar';

const containerStyle = {
  // padding: '1em 0 25em'
  height: '500px',
  overflowY: 'scroll'
};

class SectionHighlight extends Component {

  render() {
    return (
      <ContextProvider>
        <AuthConsumer>
          {({user}) =>
            <Can
              role={user.role}
              perform="active_section:view"
              yes={() => this._renderActiveSection()}
              no={() => <Unauthorized />}
            />
          }
        </AuthConsumer>
      </ContextProvider>
    )
  }

  _renderActiveSection() {
    return (
      <div className="Curation-container">
        <Container>
          <div style={containerStyle}>
            <Row>
              <Col>
                <Protected privilege="active_section:publish" show>
                  <SectionSearch />
                </Protected>
              </Col>
            </Row>
            <Row />
          </div>
          <hr />
          <Protected privilege="active_section:publish">
            <ActionBar />
          </Protected>
        </Container>
      </div>
    );
  }
}

export default SectionHighlight;