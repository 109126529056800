import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Can from '../auth/Can';
import { AuthConsumer } from '../auth/AuthContext';

class Protected extends Component {

  render() {
    const noDivProps = {};
    this.props.customBlankClassName && (noDivProps.className = this.props.customBlankClassName);
    this.props.customBlankStyle && (noDivProps.style = this.customBlankStyle);

    const child = <>{this.props.children}</>

    return (
      <AuthConsumer>
        {({user, isTest}) =>
          <Can
            role={user.role}
            perform={this.props.privilege}
            yes={() => child}
            no={() => !this.props.show ? <div {...noDivProps} /> : child}
            isTest={isTest}
          />
        }
      </AuthConsumer>
    );
  }
}

Protected.defaultProps = {
  customBlankClassName: '',
  customBlankStyle: '',
  show: false,
}

Protected.propTypes = {
  privilege: PropTypes.string,
  show: PropTypes.bool
};

export default Protected;