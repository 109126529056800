import React from 'react';
import UserPermissionList from './UserPermissionList';
import { AuthConsumer } from '../auth/AuthContext';

class PermissionSettings extends React.Component {

  render() {
    return (
      <AuthConsumer>
        {({user}) => <UserPermissionList currentRole={user.role} />}
      </AuthConsumer>
    );
  }

}

export default PermissionSettings;
