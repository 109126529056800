import React, { Component } from 'react';

import { AuthConsumer } from '../auth/AuthContext';
import Can from '../auth/Can';
import Unauthorized from '../auth/Unauthorized';
import PermissionSettings from './PermissionSettings';

class Settings extends Component {

  render() {
    return (
      <AuthConsumer>
        {({user}) =>
          <Can
            role={user.role}
            perform="settings:view"
            yes={() => <PermissionSettings />}
            no={() => <Unauthorized />}
          />
        }
      </AuthConsumer>
    );
  }

}

export default Settings;
