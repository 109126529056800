import React, { Component } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import '../../styles/Curation.css';
import Can from '../auth/Can';
import Unauthorized from '../auth/Unauthorized';
import { AuthConsumer } from '../auth/AuthContext';
import Protected from '../auth/Protected';
import ContextProvider from './CurationContext';
import CarouselContainer from './CarouselContainer';
import ActionBar from './ActionBar';
import ModeBar from './ModeBar';
import NewCurationItem from './NewCurationItem';
import EditCurationItem from './EditCurationItem';
import { RouteWithSubRoutes } from '../../routes';

class Curation extends Component {

  render() {
    return (
      <ContextProvider>
        <AuthConsumer>
          {({user}) =>
            <Can
              role={user.role}
              perform="curation:view"
              yes={() => this._renderCuration()}
              no={() => <Unauthorized />}
            />
          }
        </AuthConsumer>
      </ContextProvider>
    );
  }

  _renderCuration() {
    const { routes } = this.props;
    return (
        <div className="Curation-container">
          <Container>
            <ModeBar />
            <hr />
            <Row>
              <Col>
                <CarouselContainer />
              </Col>
            </Row>
            <hr />
            <Protected privilege="curation:publish">
              <ActionBar />
            </Protected>
          </Container>
          {routes.map((route, idx) => <RouteWithSubRoutes key={idx} {...route} />)}
        </div>
    );
  }

}

export {NewCurationItem, EditCurationItem};
export default Curation;
