import React, { Component, Fragment } from 'react';

import _ from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import Collapse from 'react-bootstrap/Collapse';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

import Paginator from "../common/Paginator";

import NotificationListItem from './NotificationListItem';
import { withContext } from './NotificationContext';
import {messageService} from '../../services';
import i18n from './messages.json';

class NotificationList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      open: props.open === true,
    }
  }

  handleDelete = async item => {
    await messageService.deleteMessage(item.id);
    let {messages, updateState, getMessages} = this.props.context;
    messages = _.filter(messages, msg => msg.id !== item.id);

    if(messages.length === 0) {
      getMessages();
      return;
    }

    updateState({messages});
  }

  handleDismiss = async item => {
    item.status = 'dismissed';
    const _item = await messageService.updateMessage(item.id, item);

    let {messages, errorMessages, updateState} = this.props.context;
    const idx = _.findIndex(messages, msg => msg.id === item.id);

    if(idx > -1) {
      messages.splice(idx, 1, _item);
    }
    const errMsgs = _.filter(errorMessages, msg => msg.status === 'error');
    updateState({
      messages,
      errorMessages: errMsgs
    });
  }

  handlePageChange = async curr => {
    const {getMessages} = this.props.context
    getMessages(curr);
  }

  toggleCollapse = () => {
    this.setState({open: !this.state.open});
  }

  render() {
    const {type = 'activity-log'} = this.props

    const title = type === 'activity-log' ?
      i18n.ink.notification.listTable.titles.activeList
      : i18n.ink.notification.listTable.titles.errorList;

    const items = type === 'activity-log' ?
      this.props.context.messages : this.props.context.errorMessages;

    return (
      <div>
        {this._renderHeader(title)}
        <Collapse in={this.state.open}>
          <div>
            {this._renderItems(items)}
            {this._renderPaginator(type)}
          </div>
        </Collapse>
      </div>
    );
  }

  _renderPaginator(type) {
    if('activity-log' !== type) {
      return <Fragment />;
    }

    const {
      itemsCountPerPage,
      pageRangeDisplayed,
      totalMessagesCount,
      activePage,
    } = this.props.context;

    return <Paginator
      activePage={activePage}
      itemsCountPerPage={itemsCountPerPage}
      totalItemsCount={totalMessagesCount}
      pageRangeDisplayed={pageRangeDisplayed}
      onChange={this.handlePageChange}
    />;
  }

  _renderHeader(title) {
    return (
      <Container className='Notif-list'>
        <Row className='header'>
          <Col>{title}</Col>
          <Col className="pull-right">
            <span className="collapse-action" onClick={this.toggleCollapse}>
              <FontAwesomeIcon icon={this.state.open ? faAngleDown : faAngleUp} />
            </span>
          </Col>
        </Row>
      </Container>
    );
  }

  _renderItems(items) {

    return (
      <Table responsive className="Notif-list">
        <thead>
          <tr>
            <th>{i18n.ink.notification.listTable.table.header.title}</th>
            <th className="status">{i18n.ink.notification.listTable.table.header.status}</th>
            <th>{i18n.ink.notification.listTable.table.header.sender}</th>
            <th>{i18n.ink.notification.listTable.table.header.deliveryTime}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          { this._renderTableBody(items) }
        </tbody>
      </Table>
    );
  }

  _renderTableBody(items) {
    if(items.length === 0) {
      const {type = 'activity-log'} = this.props

      const emptyMsg = type === 'activity-log' ?
        i18n.ink.notification.noNotifications
        : i18n.ink.notification.noErrors;

      return <tr>
        <td colSpan="5" className="no-items">{emptyMsg}</td>
      </tr>;
    }

    return _.map(items, (item, idx) => {
      return (
        <NotificationListItem
          key={idx}
          item={item}
          handleDismiss={this.handleDismiss}
          handleDelete={this.handleDelete}
        />
      );
    });
  }

}

export default withContext(NotificationList);
