import _ from 'lodash';

import { getData, putData, postData, deleteData } from '../utils/fetch-api';
const baseUrl = `${process.env.REACT_APP_API_URL}`;

class TopicService {

  constructor() {
    this.topics = null;
  }

  getTopics() {
    return getData(`${baseUrl}/messages/topics`);
  }

  addNewTopic(name) {
    return postData(`${baseUrl}/messages/topics/`, {name});
  }

  updateTopic(id, name) {
    return putData(`${baseUrl}/messages/topics/${id}`, {name});
  }

  deleteTopic(id) {
    return deleteData(`${baseUrl}/messages/topics/${id}`);
  }

  updateTopicRelationship(newTopicsRelationship) {
    const payload = newTopicsRelationship.map(
      _topicRelationship => _.pick(_topicRelationship, ['parent_id', 'child_id'])
    );
    return putData(`${baseUrl}/messages/topics/relation`, payload);
  }
}

export {TopicService};
export default new TopicService();
