import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import { BarLoader } from 'react-spinners';

import {messageService, sectionService} from '../../services';
import i18n from './messages.json';
import { _buildRootTopic } from '../../utils/common';

export const NotificationContext = React.createContext();

export function withContext(Component) {
  return function ComponentWithContext(props) {
    return (
      <NotificationContext.Consumer>
        {context => <Component {...props} context={context} />}
      </NotificationContext.Consumer>
    );
  };
}

const DEFAULT_MESSAGE = {
  title: {
    value: '',
    isValid: true,
    error: '',
  },
  body: {
    value: '',
    isValid: false,
    error: i18n.ink.notification.body.required,
  },
  expandTitle: {
    value: '',
    isValid: true,
    error: '',
  },
  expandBody: {
    value: '',
    isValid: true,
    error: '',
  },
  targeting: {
    targetType: {},
    isValid: false,
    error: i18n.ink.notification.targeting.required,
  },
  directUrl: {
    value: '',
  },
  isIncludeImage: true,
  article: null
};

export default class ContextProvider extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      updateState: this.updateState.bind(this),

      rootTopic: null,
      sectionList: [],
      topics: [],
      currentMessage: DEFAULT_MESSAGE,
      messages: [],
      errorMessages: [],

      isLoading: true,
      // pagination attrs
      activePage: 1,
      itemsCountPerPage: 25,
      pageRangeDisplayed: 5,
      totoalMessagesCount: 0,
      getMessages: this.getMessages.bind(this),
      // manage device tokens
      tokens: [],
      deleteToken: this.deleteToken.bind(this),
      setStateAsync: this.setStateAsync.bind(this)
    };
  }

  async componentDidMount() {
    let sectionList = await sectionService.getSectionList();
    const rootTopic = _buildRootTopic(sectionList) || {};

    const {docs, total} = await messageService.getMessages({
      page: 1,
      paginate: this.state.itemsCountPerPage,
      isAddTimestamp: true
    });

    const failureMessages = await messageService.getErrorMessages();

    this.setState({
      rootTopic,
      sectionList,
      activePage: 1,
      messages: docs,
      errorMessages: failureMessages.docs,
      isLoading: false,
      totalMessagesCount: total,
    });
  }

  updateState(state){
    this.setState(state);
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    });
  }

  render() {
    return this.state.isLoading
      ? this._renderLoader()
      : <NotificationContext.Provider value={this.state}>
        {this.props.children}
      </NotificationContext.Provider>;
  }

  async getMessages(page = 1, forceReload = true, message = null, isError = null) {

    if (forceReload) {
      this.setState({
        isLoading: true
      });
    }

    let messages, totalMessagesCount,errorMessages;

    if (_isEmpty(message) === true || isError === true) {
      const {
        docs,
        total
      } = await messageService.getMessages({
        page,
        paginate: this.state.itemsCountPerPage,
        isAddTimestamp: true,
      });
      const failureMessages = await messageService.getErrorMessages();

      messages = docs;
      errorMessages = failureMessages.docs;
      totalMessagesCount = total;
    } else {
      if (isError === false) {
        message = { ...message, status: 'delivered' };
      }

      messages = [message, ...this.state.messages];
      errorMessages = this.state.errorMessages;
      totalMessagesCount = this.state.totalMessagesCount + 1;
    }

    this.setState({
      activePage: page,
      messages,
      errorMessages,
      isLoading: false,
      totalMessagesCount,
    });
  }

  deleteToken(idx) {
    let {tokens} = {...this.state};
    tokens = tokens.filter( (t, index) => index !== idx);

    const promise = new Promise(
      resolve => {
        this.setState({tokens},
          resolve(true)
        );
    });
    return promise;
  }
  _renderLoader() {
    return (
      <BarLoader
        color={'#3e75c8'}
        height={2}
        widthUnit={'%'}
        width={100}
        loading={true}
      />
    );
  }

}
