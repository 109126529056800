import React from 'react';

import Modal from 'react-bootstrap/Modal';

import Login from '../components/auth/Login';

const LandingPage = props => {
  return (
    <Modal
      show={true}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>Welcome to Ink.</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          Ink is an internal tool that will be used by privileged producers
          to create, edit, configure elements for the mobile applications.
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Login />
      </Modal.Footer>
    </Modal>
  );
}

export default LandingPage;
