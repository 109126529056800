import React from 'react';

import _ from 'lodash';

import Media from 'react-bootstrap/Media';
import Card from 'react-bootstrap/Card';

const PreviewArticle = props => {
  const {image_url, story_title, author} = _.get(props, 'article', {});
  const vertical = _.get(props, 'vertical', false);

  const renderVertical = () => {
    return (
      <Card className="Shared-preview-container">
        <Card.Img variant="top" src={image_url}/>
        <Card.Body>
          <Card.Text>{story_title}</Card.Text>
          <Card.Text>
            <small className="text-muted">by {author}</small>
          </Card.Text>
        </Card.Body>
      </Card>
    );
  }

  const renderHorizontal = () => {
    return (
      <Card className="Shared-preview-container">
        <Media>
          <img
            width={120}
            className="align-self-start mr-3"
            src={image_url}
            alt="img"
          />
          <Media.Body>
            <h6>{story_title}</h6>
            <span className="Shared-preview-muted">by {author}</span>
          </Media.Body>
        </Media>
      </Card>
    );
  }

  return vertical ? renderVertical() : renderHorizontal()
};

export default PreviewArticle;
