import React from 'react';

import {permissionService} from '../../services';
import messages from './messages.json';

const Can = ({role, perform, data, yes, no, isTest}) => {
  const C = class extends React.Component {
    state = {authorized: null};

    constructor(props) {
      super(props);

      permissionService
        .authorized(role, perform, data)
        .then(authorized => !this.isUnmounted && this.setState({authorized}));
    }

    componentWillUnmount() {
      this.isUnmounted = true;
    }

    render() {
      if (isTest) return yes();

      const {authorized} = this.state;
      if(null === authorized) return (<div>{messages.ink.auth.loading}</div>);

      return authorized ? yes() : no();
    }
  };

  return <C />;
}

export default Can;
